import PageHeader from "~/components/PageHeader";
import useSWR from "swr";
import apiClient, {getFetcher} from "~/lib/apiClient";

import * as Form from "@radix-ui/react-form";
import {Button, Callout, CheckboxGroup, Heading, Table} from "@radix-ui/themes";
import TextInput from "~/components/form/TextInput";
import React, {useMemo} from "react";
import {FormField} from "@radix-ui/react-form";
import {flatMap} from "lodash";
import {useNavigate} from "react-router-dom";
import SelectInput from "~/components/form/SelectInput";

const CURRENCIES = [
  { id: 'USD', name: '🇺🇸 USD - United States Dollar' },
  { id: 'EUR', name: '🇪🇺 EUR - Euro' },
  { id: 'GBP', name: '🇬🇧 GBP - British Pound'},
  { id: 'JPY', name: '🇯🇵 JPY - Japanese Yen' },
  { id: 'AUD', name: '🇦🇺 AUD - Australian Dollar' },
  { id: 'CAD', name: '🇨🇦 CAD - Canadian Dollar' },

];
const INTERVALS = [
  { id: 'P1M', name: 'Month' },
  { id: 'P1Y', name: 'Year' },
];

export default function PricingSchemeCreatePage() {

  const [data, setData] = React.useState({
    name: null,
    lookup_key: null,
    ancestor_id: null,
  });

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [errors, setErrors] = React.useState(null);
  const navigate = useNavigate();
  
  const { data: products } = useSWR('/catalog/products?limit=9999', getFetcher);
  const { data: existingSchemes } = useSWR('/pricing/schemes?limit=9999', getFetcher);

  function handleSubmit(evt) {
    evt.preventDefault();
    setErrors(null);
    setIsSubmitting(true);

    apiClient.post(`/pricing/schemes`, data)
      .then(res => {
        console.log({res});
        navigate(`/catalog/pricing/schemes/${res.data.id}`);
      })
      .catch(err => {
        console.error(err);
        setErrors(err.response.data.errors || [err.response.data.message]);
      })
      .finally(() => {
        setIsSubmitting(false);
      })
  }

  return (
    <div className="p-10">
     <Heading as="h1" size="6">Create Pricing Scheme</Heading>

      <Form.Root onSubmit={handleSubmit} className="space-y-2">

        <TextInput
          value={data.name}
          onChange={v => setData({ ...data, name: v})}
          label="Name"
          errors={errors?.name}
        />

        <TextInput
         value={data.lookup_key}
         onChange={v => setData({ ...data, lookup_key: v})}
         beforeChangeFormatter={['slug']}
         label="Lookup Key"
         errors={errors?.lookup_key}
        />

        <SelectInput
          value={data.ancestor_id}
          name="ancestor_id"
          label="Ancestor"
          onValueChange={v => setData({ ...data, ancestor_id: v})}
          options={existingSchemes?.data.map(scheme => ({ id: scheme.id, name: scheme.name }))}
          />

        <Button loading={isSubmitting}>Submit</Button>
      </Form.Root>

      <pre>{JSON.stringify(data, null, 2)}</pre>
    </div>
  )
}
