import {useParams} from "react-router";
import PageHeader from "~/components/PageHeader";
import apiClient, {getFetcher} from "~/lib/apiClient";
import useSWR from "swr";
import Debug from "~/components/Debug";
import TextInput, { TextInputProps } from "~/components/form/TextInput";
import {get} from "lodash";
import classNames from "classnames";
import React, {useEffect, useState} from "react";
import SelectInput, { SelectInputProps } from "~/components/form/SelectInput";
import CodeInput from "~/components/form/CodeInput";
import ErrorList from "~/components/ErrorList";
import {Link, useNavigate} from "react-router-dom";
import {ChevronLeftIcon} from "@heroicons/react/16/solid";
import * as Form from "@radix-ui/react-form";
import { Paywall, PaywallIntent } from "~/types";
import { UpdatePaywallRequest } from "~/types/api/paywall";
import { Button, Checkbox, Heading } from "@radix-ui/themes";
import { toast } from "react-toastify";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator
} from "~/components/ui/breadcrumb";

const INTENT_OPTIONS = [
  {id: PaywallIntent.Upgrade, name: 'Upgrade'},
  {id: PaywallIntent.Expansion, name: 'Expansion'},
]

const MODE_OPTIONS = [
  //payment, setup
  {id: 'payment', name: 'Payment'},
  {id: 'setup', name: 'Setup'},
]
const TYPE_OPTIONS = [
  {id: 'modal', name: 'Modal'},
]

const STAGE_OPTIONS = [
  {id: 'bonjoro', name: 'Bonjoro'},
]

export default function PaywallEditPage() {

  const { paywallId } = useParams();

  const {data: paywall, error: paywallError} = useSWR(`/convert/paywalls/${paywallId}`, getFetcher<Paywall>);
  const {data: schemes, error: offeringsError} = useSWR(`/pricing/schemes`, getFetcher);
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState<UpdatePaywallRequest>();

  useEffect(() => {
    if (paywall) {
      setData({
        name: paywall.name,
        scheme_id: paywall.scheme?.id || get(schemes, 'data.0.id'),

        intent: paywall.intent,
        mode: paywall.mode,
        type: paywall.type,
        variant_key: paywall.variant_key,
        // template: JSON.stringify(paywall.template, null, 4),
        stages: JSON.stringify(paywall.stages, null, 4),
        conditions: JSON.stringify(paywall.conditions, null, 4),
        checkout_config: JSON.stringify(paywall.checkout_config, null, 4),
        can_change_interval_on_expansion: get(paywall, 'settings.can_change_interval_on_expansion', false) as boolean
      });
    }

  }, [paywall])

  const navigate = useNavigate();

  function handleSubmit(evt) {
    evt.preventDefault();
    setErrors([]);
    setIsLoading(true);

    apiClient.put(`/convert/paywalls/${paywallId}`, data).then(res => {
      navigate(`/convert/workflows/${paywall.workflow.id}/paywalls`);
      toast.success("Paywall updated");
    }).catch(err => {
      setIsLoading(false);
      // console.error("Failed to create paywall", err.response.data);
      setErrors(err.response.data.errors || [err.response.data.message]);
    })
  }

  function field(id, label) : TextInputProps {
    return {
      id,
      label,
      value: get(data, id),
      onChange: (value) => setData({...data, [id]: value}),
    }
  }
  function selectField(id, label, options): SelectInputProps {
    return {
      id,
      label,
      value: get(data, id),
      onValueChange: (value) => setData({...data, [id]: value}),
      options,
    }
  }

  if (!paywall) return <div>Loading...</div>;
  if (paywallError) return <div>Error loading paywall</div>;

  return (
    <div className="p-10">
      {/* <PageHeader navigation={<Link to={`/convert/workflows/${paywall.workflow.id}`} className="flex items-center"><ChevronLeftIcon className="w-6 h-6"/></Link>}>
        <h1 className={"text-xl font-semibold"}>Edit Paywall</h1>
      </PageHeader> */}

      <Breadcrumb className="mb-4">
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink href="/convert/workflows/">Upgrade &amp; Checkout</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator/>
          <BreadcrumbItem>
            <BreadcrumbLink href={`/convert/workflows/${paywall.workflow.id}`}>{paywall.workflow.display_name}</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator/>
          <BreadcrumbItem>
            <BreadcrumbPage>Edit Paywall</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      <Form.Root onSubmit={handleSubmit}>

        <TextInput {...field('name', 'Name')}/>

        <SelectInput {...selectField('scheme_id', 'Scheme', get(schemes, 'data', []))}/>

        <SelectInput {...selectField('intent', 'Intent', INTENT_OPTIONS)}/>
        {data?.intent === PaywallIntent.Expansion  && (
          <div className="text-xs flex space-x-2.5 mt-1">
            <span>Can select and change interval?</span>
            <Checkbox variant="soft" checked={data.can_change_interval_on_expansion} onCheckedChange={(state) => setData({ ...data, can_change_interval_on_expansion: state as boolean}) } />
          </div>
        )}
        <SelectInput {...selectField('mode', 'Mode', MODE_OPTIONS)}/>

        <SelectInput {...selectField('type', 'Type', TYPE_OPTIONS)}/>

        <TextInput {...field('variant_key', 'Variant Name')}/>

        <CodeInput {...field('stages', 'Stages')}/>

        <CodeInput {...field('conditions', 'Conditions')}/>

        <CodeInput {...field('checkout_config', 'Checkout Config')}/>

        <ErrorList errors={errors}/>

        <div className="flex items-center justify-between">
          <Button
            variant="surface"
            type="submit"
            loading={isLoading}
          >
            {isLoading ? "Saving..." : "Save"}
          </Button>
        </div>
      </Form.Root>

      {/* <Debug {...data}/> */}
    </div>
  );
}