import JSONEditor from "~/components/JSONEditor";
import * as Form from "@radix-ui/react-form"
import { useState, useEffect } from "react";

export type JSONInputProps = {
  value: object | Array<any>;
  label?: string;
  id?: string;
  onChange?: (value: object | Array<any>) => void;
  errors?: string[];
}

export default function JSONInput({ value, label, id, onChange, errors }: JSONInputProps ) {
  const [internalValue, setInternalValue] = useState("");
  const [isValidJSON, setIsValidJSON] = useState(true);

  useEffect(() => {
    setInternalValue(JSON.stringify(value, null, 2));
  }, [value]);

  function handleChange(newValue: string) {
    setInternalValue(newValue);
    try {
      const parsedJSON = JSON.parse(newValue);
      setIsValidJSON(true);
      onChange?.(parsedJSON);
    } catch (error) {
      setIsValidJSON(false);
    }
  }

  return (
    <Form.Field name={id} > 
      <Form.Label className="FormLabel">
        {label}
        {!isValidJSON && <span className="ml-2 text-yellow-600 text-xs">* Invalid JSON</span>}
      </Form.Label>

      <JSONEditor
        jsonInput={internalValue}
        setJsonInput={handleChange}
        rows={10}
      />

      {errors && (<div className="text-sm mt-2 text-rose-600">{errors[0]}</div>)}

    </Form.Field>
  )
}
