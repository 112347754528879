import { Button, Link, Table } from '@radix-ui/themes';
import { useState } from 'react';
import useSWR from 'swr';
import { RouteWrapper } from '../../components/RouteWrapper';
import { formatDateTime } from '../../utils/dateTime';
import { getFetcher } from '~/lib/apiClient';

interface UsageEvent {
  id: number;
  organization_id: number;
  customer_id: number;
  agent_id: number | null;
  client_id: number;
  event_name: string;
  unique_id: string;
  properties: {
    quantity: number;
  };
  metadata: Record<string, any> | null;
  created_at: string;
  updated_at: string | null;
  customer?: {
    id: number;
    organization_id: number;
    connection_id: number | null;
    reference_id: string;
    // ... other customer fields
  };
}

interface UsageEventsResponse {
  data: UsageEvent[];
  total: number;
  page: number;
  per_page: number;
}

export default function UsageEventsListPage() {
  const [page, setPage] = useState(1);
  const pageSize = 10;

  const { data, error, isLoading } = useSWR<UsageEventsResponse>(
    `/usage/events?page=${page}&per_page=${pageSize}`,
    getFetcher
  );

  console.log(data);

  const handlePreviousPage = () => {
    setPage((prev) => Math.max(1, prev - 1));
  };

  const handleNextPage = () => {
    if (data && page * pageSize < data.total) {
      setPage((prev) => prev + 1);
    }
  };

  return (
    <div className="p-6">
      <Table.Root>
        <Table.Header>
          <Table.Row>
            <Table.ColumnHeaderCell>Customer Info</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Event Type</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Properties</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Timestamp</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Metadata</Table.ColumnHeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {data?.data.map((event) => (
            <Table.Row key={event.id}>
              <Table.Cell>
                <div>
                  <Link href={`/customers/${event.customer.id}`}>Customer {event.customer?.email || event.customer?.id}</Link>
                </div>
                <div>Unique ID: {event.unique_id}</div>
              </Table.Cell>
              <Table.Cell>{event.event_name}</Table.Cell>
              <Table.Cell>
                <pre>{JSON.stringify(event.properties, null, 2)}</pre>
              </Table.Cell>
              <Table.Cell>{formatDateTime(event.created_at)}</Table.Cell>
              <Table.Cell>
                <pre>{event.metadata && JSON.stringify(event.metadata, null, 2)}</pre>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table.Root>

      <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px', marginTop: '16px' }}>
        <Button
          onClick={handlePreviousPage}
          disabled={page === 1}
        >
          Previous
        </Button>
        <Button
          onClick={handleNextPage}
          disabled={!data || page * pageSize >= data.total}
        >
          Next
        </Button>
      </div>
    </div>
  );
}
