import { Plan } from "./plan";


export interface Link {
  id: string
  data: object;
  provider_id: string;
  provider_name: string;
}
export interface Product {
  id: string
  name: string
  description: string
  ancestor: Product | null
  successor: Product | null
  descendants: Product[]
  created_at: string
  updated_at: string
  published_at?: string | null
  archived_at?: string | null
  links: Link[]
}

export interface ProductFeature {
  id: string;
  name: string;
  description: string;
  note: string;
  allowance: string;
  unit: string;
  reset_period: string;
  created_at: string;
}

export type Organization = {
  id: string;
  object: string;
  name: string;
  created_at: string;
  updated_at: string;
}

export type User = {
  email: string;
  first_name: string;
  last_name: string;
  password: string;
  timezone: string;
  email_verified_at: string;
  current_organization: Organization;
  organizations: Organization[];
}

export type Client = {
  id: string;
  name: string;
  type: string;
  object: string;
}

export type Workflow = {
  clients: (Client & { workflow_enabled: boolean })[];
  id: number;
  name: string;
  object: string;
  triggers: object[]; //create trigger type
  updated_at: string;
  created_at: string;
}

export enum Criteria {
  ScheduleQuantity = 'scheduleQuantity',
  SubscribedToProduct = 'isSubscribedToProduct',
  SubscribedToPlan = 'isSubscribedToPlan',
  HasEntitlement = 'hasEntitlement',
  ProductSubscriptions = 'productSubscriptions'
}

export enum NumberComparisonOperators {
  GreaterThan = 'gt',
  LessThan = 'lt',
  Equal = 'eq',
  In = 'in',
  NotEqual = 'ne',
  NotIn = 'nin'
}

export interface Rule {
  criteria: Criteria;
  arg?: any;
  op?: NumberComparisonOperators;
  value?: any;
}
export interface Conditions {
  rules?: Rule[];
  combinator?: "and" | "or";
  not?: boolean;
}

export interface Scheme {
  id: string;
  key: string;
  name: string;
  object: 'scheme';
}

export enum PaywallIntent {
  Upgrade = 'upgrade',
  Expansion = 'expansion'
}

export type Paywall = {
  checkout_config: Record<string, unknown>;
  id: string;
  workflow: Workflow;
  conditions?: Conditions;
  settings: Record<string, unknown>;
  name: string;
  scheme: Scheme;
  stages: Record<string, unknown>;
  template: Record<string, unknown>;
  variant_key: string;
  type: string;
  intent: PaywallIntent;
  mode: string;
}

export type ProductFamily = {
  icon?: {
    href: string;
  };
  id: string;
  name: string;
  lookup_key: string;
  products?: Product[];
}
