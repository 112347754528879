import React from "react";

export default function ErrorList({ errors }) {
  // console.log('ErrorList', { errors });
  if (!errors || !Object.keys(errors).length) return;

  return (
    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
      <strong className="font-bold">Error</strong>
      <ul>
        {Object.keys(errors).map((k, i) => (
          <li key={i}>{errors[k]}</li>
        ))}
      </ul>
    </div>
  )
}
