import React, {useEffect, useState} from "react";
import apiClient, {getFetcher} from "~/lib/apiClient";
import useSWR from "swr";
import PageHeader from "~/components/PageHeader";
import {get} from "lodash";
import {useParams} from "react-router";
import {useNavigate} from "react-router-dom";

import bonjoroStage from "~/stage-json/bonjoro-stages";
import Debug from "~/components/Debug";
import TextInput, { TextInputProps } from "~/components/form/TextInput";
import SelectInput, { SelectInputProps } from "~/components/form/SelectInput";
import ErrorList from "~/components/ErrorList";
import CodeInput from "~/components/form/CodeInput";
import {Form} from "@radix-ui/react-form";
import Alert from "~/components/Alert";
import {Button, Checkbox, Heading, Link} from "@radix-ui/themes";
import { PaywallIntent } from "~/types";
import { CreatePaywallRequest, UpdatePaywallRequest } from "~/types/api/paywall";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator
} from "~/components/ui/breadcrumb";
import { toast } from "react-toastify";

const INTENT_OPTIONS = [
  {id: PaywallIntent.Upgrade, name: 'Upgrade'},
  {id: PaywallIntent.Expansion, name: 'Expansion'},
]

const MODE_OPTIONS = [
  //payment, setup
  {id: 'payment', name: 'Payment'},
  {id: 'setup', name: 'Setup'},
]
const TYPE_OPTIONS = [
  {id: 'modal', name: 'Modal'},
]

const STAGE_OPTIONS = [
  {id: 'bonjoro', name: 'Bonjoro'},
]

export default function PaywallCreatePage() {

  const {workflowId} = useParams();
  const navigate = useNavigate();

  function handleSubmit(evt) {
    evt.preventDefault();
    setErrors([]);
    setIsLoading(true);

    apiClient.post(`/convert/workflows/${workflowId}/paywalls`, data).then(res => {
      toast.success("Paywall created");
      navigate(`/convert/workflows/${res.data.workflow.id}/paywalls`);
    }).catch(err => {
      setIsLoading(false);
      console.error("Failed to create paywall", );
      setErrors(err.response.data.errors || [err.response.data.message]);
    })
  }

  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const {data: schemes, error: offeringsError} = useSWR(`/pricing/schemes`, getFetcher);

  const [data, setData] = useState<CreatePaywallRequest>({
    name: 'New Paywall',
    scheme_id: get(schemes, 'data.0.id'),
    intent: PaywallIntent.Upgrade,
    variant_key: null,
    weight: 100,
    mode: 'setup',
    type: 'modal',
    // template: JSON.stringify({template: {layout: 'split-checkout-v1'}}, null, 4),
    stages: JSON.stringify(bonjoroStage, null, 4),
    conditions: JSON.stringify([], null, 4),
    checkout_config: JSON.stringify({
      line_items: [
        {
          id: '<line_item_1>',
          object: 'plan',
        },
      ]
    }, null, 4),
    can_change_interval_on_expansion: false
  });

  useEffect(() => {
    if (schemes) {
      setData({...data, scheme_id: get(schemes, 'data.0.id')});
    }
  }, [schemes]);

  function field(id, label): TextInputProps {
    return {
      id,
      label,
      value: get(data, id),
      errors: get(errors, id),
      onChange: (value) => setData({...data, [id]: value}),
    }
  }

  function selectField(id, label, options): SelectInputProps {
    return {
      id,
      label,
      value: get(data, id),
      errors: get(errors, id),
      onValueChange: (value) => setData({...data, [id]: value}),
      options,
    }
  }

  return (
    <div className="p-10">
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink href="/convert/workflows/">Upgrade &amp; Checkout</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator/>
          <BreadcrumbItem>
            <BreadcrumbLink href={`/convert/workflows/${workflowId}`}>Upgrade &amp; Checkout</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator/>
          <BreadcrumbItem>
            <BreadcrumbPage>Create Paywall</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      <PageHeader>
        <Heading >Create Paywall</Heading>
      </PageHeader>

      <Form onSubmit={handleSubmit} className="space-y-2">

        <TextInput {...field('name', 'Name')}/>

        <SelectInput {...selectField('scheme_id', 'Pricing Scheme', get(schemes, 'data', []))}/>
        {schemes?.data.length === 0 && (
          <Alert>No offerings, please create one <Link href={`/catalog/pricing`}>here</Link></Alert>
        )}


        <SelectInput {...selectField('intent', 'Intent', INTENT_OPTIONS)}/>
        {data?.intent === PaywallIntent.Expansion  && (
          <div className="text-xs flex space-x-2.5 mt-1">
            <span>Can select and change interval?</span>
            <Checkbox variant="soft" checked={data.can_change_interval_on_expansion} onCheckedChange={(state) => setData({ ...data, can_change_interval_on_expansion: state as boolean}) } />
          </div>
        )}

        <SelectInput {...selectField('mode', 'Mode', MODE_OPTIONS)}/>

        <SelectInput {...selectField('type', 'Type', TYPE_OPTIONS)}/>

        <TextInput {...field('variant_key', 'Variant Name')}/>

        {/*<CodeInput {...field('template', 'Template')}/>*/}

        <CodeInput {...field('stages', 'Stages')}/>

        <CodeInput {...field('conditions', 'Conditions')}/>

        <CodeInput {...field('checkout_config', 'Checkout Config')}/>

        <ErrorList errors={errors} />

        {/*<ComboboxDemo/>*/}

        <div className="flex items-center justify-between">
          <Button loading={isLoading} type="submit">Create</Button>
        </div>


      </Form>

      {/*<Debug {...data}/>*/}
    </div>
  )
}
