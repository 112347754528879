import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import * as Form from '@radix-ui/react-form';
import { Button, Heading, Table } from '@radix-ui/themes';
import { toast } from 'react-toastify';
import apiClient, { getFetcher } from '~/lib/apiClient';
import TextInput from '~/components/form/TextInput';
import JSONInput from '~/components/form/JSONInput';
import Pagination from '~/components/Pagination';
import classNames from 'classnames';
import { CircleCheck, CirclePlay, MousePointerClick } from 'lucide-react';

interface FlowData {
  id: string;
  lookup_key: string;
  display_name: string;
  current_state: 'draft' | 'active' | 'inactive' | 'archived';
  scenarios: any[];
  inactivity_timeout_seconds: number | null;
}

function FlowActivitiesTable({ flow }: { flow: FlowData }) {

  const [currentPage, setCurrentPage] = useState(1);
  const { data: activities, error, mutate } = useSWR<Activity[]>(
    `/convert/flows/${flow.id}/activities?page=${currentPage}`,
    getFetcher
  );

  if (error) return <div>Failed to load activities</div>;
  if (!activities?.data) return <div>Loading...</div>;

  return (
   <div>
     <Table.Root>
      <Table.Header>
        <Table.Row>
          <Table.ColumnHeaderCell>ID</Table.ColumnHeaderCell>
          {/* <Table.ColumnHeaderCell>Name</Table.ColumnHeaderCell> */}
          {/* <Table.ColumnHeaderCell>Last Interaction At</Table.ColumnHeaderCell> */}
          <Table.ColumnHeaderCell>Events</Table.ColumnHeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {activities.data.map((activity) => (
          <Table.Row key={activity.id}>
            <Table.Cell>
              <div className="font-bold">{activity. country_flag}</div>
              <div>{activity.current_state}</div>
            </Table.Cell>
            {/* <Table.Cell></Table.Cell> */}
            {/* <Table.Cell>{activity.last_interaction_at}</Table.Cell> */}
            <Table.Cell>

              <div>Handler: {activity.handler?.event_name}</div>
              <div>Flow: {activity.flow?.display_name}</div>

            <ul role="list" className="pt-4">
                {activity.events.map((evt, i) => {
                  return (
                    <li key={i}>
                      <div className="relative pb-8">
                        {i !== activity.events.length - 1 ? (
                          <span aria-hidden="true" className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200" />
                        ) : null}
                        <div className="relative flex space-x-3">
                          <div>
                            <span
                              className={classNames(
                                // event.iconBackground,
                                'flex h-8 w-8 items-center justify-center rounded-full ring-8 ring-white',
                              )}
                            >
                              {evt.event_name === 'interaction' && <MousePointerClick className="h-5 w-5 text-black" />}
                              {evt.event_name === 'started' && <CirclePlay className="h-5 w-5 text-black" />}
                              {evt.event_name === 'finished' && <CircleCheck className="h-5 w-5 text-black" />}
                            </span>
                          </div>
                          <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                            <div>
                              <p className="text-sm text-gray-800">
                                <div>{evt.descriptor}{' '}</div>
                                {evt.data?.state === 'cancel' && (
                                  <span className="text-red-500">Canceled</span>
                                )}
                      
                                {evt.data?.state === 'complete' && (
                                  <span className="text-green-500">Completed</span>
                                )}
                                {/* <a href={event.href} className="font-medium text-gray-900">
                                  {event.target}
                                </a> */}
                                <pre>{JSON.stringify(evt.data, null, 2)}</pre>
                              </p>
                            </div>
                            <div className="whitespace-nowrap text-right text-sm text-gray-500">
                              <time dateTime={evt.datetime}>{evt.datetime}</time>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  )
                })}
              </ul>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table.Root>
    <Pagination 
      currentPage={activities.current_page}
      totalPages={activities.total_pages}
      onPageChange={(page) => {
        setCurrentPage(page);
      }}
    />
   </div>
  );
}
const FlowDetailPage: React.FC = () => {
  const { flowId } = useParams<{ flowId: string }>();
  const { data: flowData, error, mutate } = useSWR<FlowData>(
    `/convert/flows/${flowId}`,
    getFetcher
  );

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [data, setData] = useState<FlowData | null>(null);

  useEffect(() => {
    if (flowData) {
      setData({...flowData, lookup_key: flowData.id });
    }
  }, [flowData]);

  if (error) return <div>Failed to load flow</div>;
  if (!data) return <div>Loading...</div>;

  const field = (name: keyof FlowData, label: string) => {
    const fieldErrors = Object.entries(errors)
      .filter(([key]) => key === name || key.startsWith(`${name}.`))
      .map(([, value]) => value);

    return {
      name,
      label,
      value: data[name],
      errors: fieldErrors,
      onChange: (value: string | any[]) => {
        setData({ ...data, [name]: value });
      },
    };
  };

  const handleSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    setIsSubmitting(true);

    apiClient
      .put(`/convert/flows/${flowId}`, data)
      .then(() => {
        toast.success('Flow updated');
        mutate();
      })
      .catch((err) => {
        setErrors(err.response.data.errors || { general: err.response.data.message });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <div className="p-10">
      <Heading size="5" as="h2">Activities</Heading>
      
      <FlowActivitiesTable flow={flowData}/>


      <Heading size="5" as="h2">Edit Flow</Heading>
      <Form.Root onSubmit={handleSubmit}>
        <div className="mb-4">
          <TextInput
            {...field('display_name', 'Display Name')}
          />

          <TextInput
            {...field('lookup_key', 'Lookup Key')}
          />

          <TextInput
            {...field('current_state', 'Current State')}
          />

          <JSONInput
            {...field('scenarios', 'Scenarios')}
          />

          <TextInput
            {...field('inactivity_timeout_seconds', 'Inactivity Timeout Seconds')}
          />
        </div>

        <div className="py-4">
          <Button type="submit" disabled={isSubmitting}>Update Flow</Button>
        </div>
      </Form.Root>
      {errors.general && <div className="text-red-500 mt-2">{errors.general}</div>}
      <pre>{JSON.stringify(errors, null, 2)}</pre>
      <pre>{JSON.stringify(data, null, 2)}</pre>
    </div>
  );
};

export default FlowDetailPage;
