import useSWR from "swr";
import apiClient, { getFetcher } from "~/lib/apiClient";

import * as Form from '@radix-ui/react-form'
import { Button, TextField, Heading } from "@radix-ui/themes";
import TextInput from "~/components/form/TextInput";
import { useState } from "react";
import JSONEditor from "~/components/JSONEditor";
import { CodeIcon } from "lucide-react";
import CodeInput from "~/components/form/CodeInput";
import { toast } from "react-toastify";
import SelectInput from "~/components/form/SelectInput";
import { Table } from "@radix-ui/themes";
import AnchorButton from "~/components/AnchorButton";
import JSONInput from "~/components/form/JSONInput";
import { Link } from "react-router-dom";
import PageHeader from "~/components/PageHeader";

interface ElementData {
  data: Element[];
}

export interface Element {
  lookup_key: string;
  // either id?
  // placement: string;
  type: 'gate' | 'banner';
  current_state: 'draft' | 'active' | 'inactive' | 'archived';
  conditions: any[]; // Consider defining a more specific type for conditions
  event_handlers: {
    [key: string]: EventHandler;
  };
}
const conditions = {
  // rules = 
  "rules": [
      {
          "op": "eq",
          "arg": "video-messaging",
          "value": 1,
          "criteria": "scheduleQuantity"
      },
      {
          "value": [
              "prod_OZcQ6CjA7CA24W",
              "prod_OZcOE19m8QyNbp"
          ],
          "criteria": "isSubscribedToProduct"
      },
      {
          "op": "nin",
          "value": [
              "prod_PWQZyE5Vwyy4QC"
          ],
          "criteria": "productSubscriptions"
      }
  ],
  "combinator": "and"
}

interface EventHandler {
  action: 'startFlow';
  context?: object;
}

export interface ElementDataContainer {
  id: string;
  display_name: string;
  type: 'gate' | 'banner';
  current_state: 'draft' | 'active' | 'inactive' | 'archived';
  conditions: object;
  event_handlers: {
    [key: string]: EventHandler;
  };
  insertion_rules: any | null;
  insertion_type: 'manual' | 'automatic';
}

const DEFAULT_FORM_DATA = {
  lookup_key: null,
  current_state: 'draft',
  type: 'gate',
  // placement: '',
  // if matches conditions, they will get gated
  conditions: [
    // or, or, or?
  ],
  event_handlers: {
    unlock: {
      type: 'flow',
    }
  }
}

const CreateElementForm = ({ mutate }: { mutate: () => void }) => {

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [errors, setErrors] = useState<string[]>([])
  const [data, setData] = useState<ElementDataContainer>(DEFAULT_FORM_DATA)

  const field = (name: string, label: string) => {
    return {
      name,
      label,
      value: data[name],
      errors: errors[name],
      onChange: (value: string) => {
        setData({ ...data, [name]: value })
      }
    }
  }

  const handleSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    setIsSubmitting(true);

    console.log(data)
    apiClient.post('/convert/elements', data).then((res) => {
      console.log(res)
      toast.success('Element created')
      mutate()
    }).catch((err) => {
    
      setErrors(err.response.data.errors || [err.response.data.message])
    }).finally(() => {
      setIsSubmitting(false)
    })
  }

  function selectField(name: string, label: string) {
    return {
      name,
      label,
      value: data[name],
      errors: errors[name],
      onChange: (value: string) => {
        // console.log('value', value)
        setData({ ...data, [name]: value })
      } 
    }
  }

  return (
    <Form.Root onSubmit={handleSubmit} className="border p-4">
      <h1>Create Element</h1>

      <div className="mb-4">
        <TextInput
          {...field('display_name', 'Display Name')}
        />

        <TextInput
          {...field('lookup_key', 'Lookup Key')}
        />

        <SelectInput
          options={[
            { name: 'Gate', id: 'gate' },
          ]}
          {...selectField('type', 'Type')}
        />

        <JSONInput
          {...field('conditions', 'Conditions')}
        />

        <JSONInput
          {...field('event_handlers', 'Event Handlers')}
        />
      </div>

      <Button disabled={isSubmitting}>Create</Button>

      {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
    </Form.Root>
  )
}

const DEFAULT_FLOW_DATA = {
  lookup_key: null,
  display_name: null,
  current_state: 'drat',
  scenarios: [

  ],
  inactivity_timeout_seconds: null,
}

function CreateFlowForm({ mutate }: { mutate: () => void }) {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [errors, setErrors] = useState<string[]>([])
  const [data, setData] = useState<ElementDataContainer>(DEFAULT_FLOW_DATA)

  const handleSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    setIsSubmitting(true);

    console.log(data)
    apiClient.post('/convert/flows', data).then((res) => {
      console.log(res)
      toast.success('Flow created')
      mutate()
    }).catch((err) => {
      setErrors(err.response.data.errors || [err.response.data.message])
    }).finally(() => {
      setIsSubmitting(false)
    })
  }

  const field = (name: string, label: string) => {
    return {
      name,
      label,
      value: data[name],
      errors: errors[name],
      onChange: (value: string) => {
        setData({ ...data, [name]: value })
      }
    }
  } 

  return (
    <Form.Root onSubmit={handleSubmit} className="border p-4">
      <h1>Create Flow</h1>

    
      <TextInput
        {...field('display_name', 'Display Name')}
      />

      <TextInput
        {...field('lookup_key', 'Lookup Key')}
      />

      <JSONInput
        {...field('scenarios', 'Scenarios')}
      />

      <TextInput
        {...field('inactivity_timeout_seconds', 'Inactivity Timeout Seconds')}
      />


      <div className="py-4">
      <Button disabled={isSubmitting}>Create</Button>
      </div>

  
    </Form.Root>
  )
}
function FlowsListPage() {
  const { data: flowsData, isLoading, error, mutate } = useSWR<FlowData[]>('/convert/flows', getFetcher);

  if (isLoading) {
    return <div>Loading...</div>
  }

  return (
    <Table.Root> 
      <Table.Header>
        <Table.Row>
          <Table.ColumnHeaderCell>ID</Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell>Display Name</Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell>Lookup Key</Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell>Current State</Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell>Inactivity Timeout Seconds</Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell>
            Edit
          </Table.ColumnHeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {flowsData?.data.map((flow) => (
          <Table.Row key={flow.id}>
            <Table.Cell>
              <Link to={`/convert/flows/${flow.id}`}>
                {flow.id}
              </Link>
            </Table.Cell>
            <Table.Cell>{flow.display_name}</Table.Cell>
            <Table.Cell>{flow.lookup_key}</Table.Cell>
            <Table.Cell>{flow.current_state}</Table.Cell>
            <Table.Cell>{flow.inactivity_timeout_seconds}</Table.Cell>
            <Table.Cell>  
              <AnchorButton href={`/convert/flows/${flow.id}`}>Edit</AnchorButton>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table.Root>
  )
}


const ElementsListPage = () => {

  const { data: elementsData, isLoading, error, mutate } = useSWR<ElementData[]>('/convert/elements', getFetcher);
  const { data: flowsData, error: flowsError, mutate: mutateFlows } = useSWR<FlowData[]>('/convert/flows', getFetcher);

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (error) {
    return <div>Error loading elements: {error.message}</div>
  }

  return (
    <div className="p-10 space-y-4">
      <PageHeader>
        <Heading>Conversion Elements</Heading>
      </PageHeader>
      
      <CreateElementForm mutate={mutate} />

      <CreateFlowForm mutate={mutateFlows} />

      <Heading as="h2" size="5">Flows</Heading>
      
      <FlowsListPage
      />
      
      <Heading as="h2" size="5">Elements</Heading>

      <Table.Root>
        <Table.Header>
          <Table.Row>
            <Table.ColumnHeaderCell>ID</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Display Name</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Type</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Current State</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Insertion Type</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>
              Edit
            </Table.ColumnHeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {elementsData?.data.map((element) => (
            <Table.Row key={element.id}>
              <Table.Cell>{element.id}</Table.Cell>
              <Table.Cell>{element.display_name}</Table.Cell>
              <Table.Cell>{element.type}</Table.Cell>
              <Table.Cell>{element.current_state}</Table.Cell>
              <Table.Cell>{element.insertion_type}</Table.Cell>

              <Table.Cell>  
                <AnchorButton href={`/convert/elements/${element.id}`}>
                  Edit
                </AnchorButton>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table.Root>
  
    </div>
  )
}

export default ElementsListPage;