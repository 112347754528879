import { Checkbox, Link, Table } from "@radix-ui/themes";
import { getFetcher } from "~/lib/apiClient";

import useSWR from "swr";
import ProductStatusBadge from "~/components/ui/ProductStatusBadge";
import { Product, ProductFamily } from "~/types";

export type ProductListProps = {
  family?: ProductFamily;
  selectedProducts?: string[];
  onSelectChange: (selectedProducts: string[]) => void;
}
export default function ProductList({ family, selectedProducts = [], onSelectChange }: ProductListProps) {
  const { data: products, error } = useSWR('/catalog/products', getFetcher);

  const toggleCheck = (product: Product) => (checked: boolean) => {
    if (checked) {
      onSelectChange([...selectedProducts, product.id]);
    } else {
      onSelectChange(selectedProducts.filter(productId => product.id !== productId));
    }
  }

  if (!products) return <div>Loading...</div>;

  return (
    <>
      <Table.Root variant="surface" layout="fixed" className="TableRootHeaderOnly">
      <Table.Header>
        <Table.Row>
          <Table.ColumnHeaderCell colSpan={2}>Product</Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell>Status</Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell>Select</Table.ColumnHeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
      {products.data.map((product, i) => (
              <Table.Row>
                <Table.Cell colSpan={2}>
                  <Link href={`/catalog/products/${product.id}`} weight="medium" color="gray" highContrast>{product.name}</Link>
                </Table.Cell>
                <Table.Cell>
                  <ProductStatusBadge status={product.status} />
                </Table.Cell>
                <Table.Cell>
                  <Checkbox
                    checked={selectedProducts.includes(product.id)}
                    onCheckedChange={toggleCheck(product)}
                    disabled={product.family !== null && product.family !== family?.lookup_key}
                  />
                  {(product.family !== null && product.family !== family?.lookup_key) && (
                    <Link href={`/catalog/product_families/${product.family}`} color="gray" underline={"hover"} className="ml-2">{product.family}</Link>
                  )}
                </Table.Cell>
              </Table.Row>
          ))}
        </Table.Body>
      </Table.Root>
      
    </>
  )
}
