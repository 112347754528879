import { getFetcher } from "~/lib/apiClient";
import useSWR from "swr";
import { useState } from "react";
import { useSearchParams } from 'react-router-dom';

import { Button, Heading, SegmentedControl, Table, Link, Badge } from "@radix-ui/themes";
import Alert from "~/components/Alert";
import AnchorButton from "~/components/AnchorButton";
import DateComponent from "~/components/DateComponent";
import ProductStatusBadge from "~/components/ui/ProductStatusBadge";
import { PlanStatusBadge } from "~/components/ui/PlanStatusBadge";
import PageHeader from "~/components/PageHeader";
import { ClipboardCopyIcon } from "lucide-react";




function OfferingListItem({ offering }) {
  return (
    <div className="border rounded-lg flex flex-col overflow-hidden">
      <div className="flex justify-between py-3 px-4 bg-gray-50 ">
        <Link to={`/catalog/pricing/schemes/${offering.id}`} className="block w-full ">
          <Heading as={"h2"} size="4">
            {offering.name}
          </Heading>
        </Link>
        <div className="flex gap-4 items-center">

          <div>
            {offering.status}
          </div>
        </div>
      </div>


      {offering.products.data.map((product, i) => (
        <div className="flex justify-between px-4 py-3 items-center gap-4" >
          <Link className="block" to={`/catalog/products/${product.id}`}>
            <Heading as="h4" size={"3"} className="flex gap-4 items-center">
              {product.name}
              <span className="text-xs font-medium text-gray-500 ">{product.status}</span>
            </Heading>
          </Link>
          <div className="flex gap-4">
            <DateComponent timestamp={product.published_at} />
            <div className="text-gray-600">
              {offering.plans.length === 0 && <div>No prices</div>}
              {offering.plans.length === 1 && <div>One price</div>}
              {offering.plans.length > 1 && <div>Multiple prices</div>}
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default function PricingSchemeListPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [view, setView] = useState(searchParams.get('view') || 'packages');

  const handleViewChange = (newView: string) => {
    setView(newView);
    setSearchParams({ view: newView });
  };

  const { data: pricingSchemes, error } = useSWR('/pricing/schemes', getFetcher);
  const { data: products } = useSWR('/catalog/products?limit=9999', getFetcher);

  const getRenewalInterval = (plan) => {
    switch (plan.renew_interval) {
      case 'P1M': return `m`;
      case 'P1Y': return `y`;
      default: return 'Custom'
    }
  }

  if (error) {
    return <div>
      <Alert title="Error while loading offerings">
        <div>{error?.message}</div>
        <Button onClick={() => window.location.reload()}>Reload</Button>
      </Alert>
    </div>
  }
  if (!pricingSchemes) return <div>Loading...</div>;

  return (
    <>
      <div className="space-y-4 p-10">
        <PageHeader>
          <div className="flex items-center gap-4">
            <Heading>Pricing Schemes</Heading>
            <AnchorButton variant="ghost" href={`/catalog/pricing/schemes/create`}>Add Scheme</AnchorButton>
          </div>
        </PageHeader>

        <div className="flex items-center justify-between">
          <SegmentedControl.Root 
            variant="surface" 
            value={view} 
            onValueChange={handleViewChange}
          >
            <SegmentedControl.Item value="packages">Packages</SegmentedControl.Item>
            <SegmentedControl.Item value="products">Products</SegmentedControl.Item>
            <SegmentedControl.Item value="plans">Plans</SegmentedControl.Item>
          </SegmentedControl.Root>
          {view === 'packages' && (
            <AnchorButton variant="outline" href={`/catalog/pricing/packages/create`}>Create New Package</AnchorButton>
          )}
          {view === 'products' && (
            <AnchorButton variant="outline" href={`/catalog/products/create`}>Create New Product</AnchorButton>
          )}
          {view === 'plans' && (
            <AnchorButton variant="outline" href={`/catalog/pricing/plans/create`}>Create New Plan</AnchorButton>
          )}
        </div>

        <Table.Root variant="surface" layout="fixed" className="TableRootHeaderOnly">
          <Table.Header>
            {view === 'packages' && (
              <Table.Row>
                <Table.ColumnHeaderCell colSpan={3}>Packages</Table.ColumnHeaderCell>
                <Table.ColumnHeaderCell># Plans</Table.ColumnHeaderCell>
                <Table.ColumnHeaderCell>Currencies</Table.ColumnHeaderCell>
                <Table.ColumnHeaderCell>Billing Intervals</Table.ColumnHeaderCell>
              </Table.Row>
            )}
            {view === 'products' && (
              <Table.Row>
                <Table.ColumnHeaderCell colSpan={2}>Product</Table.ColumnHeaderCell>
                <Table.ColumnHeaderCell>Status</Table.ColumnHeaderCell>
                <Table.ColumnHeaderCell>Family</Table.ColumnHeaderCell>
                <Table.ColumnHeaderCell>Date published</Table.ColumnHeaderCell>
                <Table.ColumnHeaderCell>Last updated</Table.ColumnHeaderCell>
                <Table.ColumnHeaderCell># Plans</Table.ColumnHeaderCell>
              </Table.Row>
            )}
            {view === 'plans' && (
              <Table.Row>
                <Table.ColumnHeaderCell colSpan={2}>Plan</Table.ColumnHeaderCell>
                <Table.ColumnHeaderCell colSpan={2}>Identifier</Table.ColumnHeaderCell>
                <Table.ColumnHeaderCell>Type</Table.ColumnHeaderCell>
                <Table.ColumnHeaderCell>Status</Table.ColumnHeaderCell>
                {/* <Table.ColumnHeaderCell>Last Updated</Table.ColumnHeaderCell> */}
              </Table.Row>
            )}
          </Table.Header>
        </Table.Root>

        {view === 'products' && (
          <Table.Root variant="surface" layout="fixed">
             {products?.data.map((product, i) => (
              <Table.Row>
                <Table.Cell colSpan={2}>
                  <Link href={`/catalog/products/${product.id}`} weight="medium" color="gray" highContrast>{product.name}</Link>
                </Table.Cell>
                <Table.Cell>
                  <ProductStatusBadge status={product.status} />
                </Table.Cell>
                <Table.Cell>
                  {product.family}
                </Table.Cell>
                <Table.Cell>
                  {product.published_at && (<DateComponent timestamp={product.published_at} />)}
                </Table.Cell>
                <Table.Cell>
                  {product.updated_at && (<DateComponent timestamp={product.updated_at} />)}
                </Table.Cell>
                <Table.Cell>{product.plans_count || '-'}</Table.Cell>
                {/*<Table.Cell>{product.schedules?.length}</Table.Cell>*/}
              </Table.Row>
            ))}
          </Table.Root>
        )}

        {["plans", "packages"].includes(view) && pricingSchemes.data.map((scheme, i) => (
          <Table.Root variant="surface" layout="fixed">
            <Table.Header>
              <Table.Row>
                <Table.ColumnHeaderCell colSpan={6} align="center">
                  <Link href={`/catalog/pricing/schemes/${scheme.id}`} color="gray" underline={"hover"}>{scheme.name}</Link>
                </Table.ColumnHeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {scheme.packages.data.length === 0 && (
                <Table.Row>
                  <Table.Cell colSpan={6} align="center">No packages found</Table.Cell>
                </Table.Row>
              )}

              {view === 'packages' && scheme.packages.data.map((pkg, i) => (
                <Table.Row>
                  <Table.Cell colSpan={3}>
                    <Link href={`/catalog/pricing/packages/${pkg.id}`} weight="medium" color="gray" highContrast>{pkg.name}</Link>
                  </Table.Cell>
                  <Table.Cell>
                    {scheme.plans.data.filter(p => p.package?.id === pkg.id).length}
                  </Table.Cell>
                  <Table.Cell>
                    {pkg.currencies?.map(c => `${c.currency_code} ${c.currency_flag}`).join(', ')}
                  </Table.Cell>
                  <Table.Cell>
                    {pkg.intervals?.map(i => i.label).join(', ')}
                  </Table.Cell>
                  {/*<Table.Cell>*/}
                  {/*  {pkg.updated_at && (<DateComponent timestamp={pkg.updated_at}/>)}*/}
                  {/*</Table.Cell>*/}
                  {/*<Table.Cell>{pkg.schedules?.length}</Table.Cell>*/}
                </Table.Row>
              ))}

              {view === 'plans' && scheme.plans.data.map((plan, i) => (
                <Table.Row>
                  <Table.RowHeaderCell colSpan={2}>
                    <Link href={`/catalog/pricing/plans/${plan?.id}`} color="gray" highContrast underline={"hover"} className="block whitespace-nowrap">
                      <div className="font-medium flex items-center gap-2 justify-between">
                        <div>{plan.package?.name} </div>
                        <Badge color="gray">{plan.currency_code} {plan.currency_flag} {plan.renew_interval}</Badge>
                      </div>
                    </Link>
                  </Table.RowHeaderCell>

                  <Table.Cell colSpan={2}>
                    <div className="flex gap-4 items-center justify-between">
                      <div className="text-gray-800 select-all">{plan.id}</div>
                      <Button size="1" variant="ghost" color="gray" onClick={() => {
                        navigator.clipboard.writeText(plan.id)
                      }}><ClipboardCopyIcon size={16} color="#999" /></Button>
                    </div>
                  </Table.Cell>
                  
                  <Table.Cell>
                    {plan.type}
                  </Table.Cell>

                  <Table.Cell>
                    <PlanStatusBadge status={plan.status} />
                  </Table.Cell>
                  {/* <Table.Cell>
                    {plan.updated_at && (<DateComponent timestamp={plan.updated_at}/>)}
                  </Table.Cell> */}
                  {/*<Table.Cell>{plan.schedules?.length}</Table.Cell>*/}
                </Table.Row>
              ))}
            </Table.Body>
          </Table.Root>
        ))}

        {!pricingSchemes.data.length && (
          <div className="text-center text-gray-500">No schemes found</div>
        )}
      </div>
    </>
  )
}
