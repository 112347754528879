import { useParams, useSearchParams } from "react-router-dom";
import WorkflowLayout from "./WorkflowLayout";
import useSWR from "swr";
import apiClient from "~/lib/apiClient";
import { Heading, Table, Button, Dialog } from "@radix-ui/themes";
import { format } from "date-fns";
import Pagination from "~/components/Pagination"; // Adjust the import path as needed
import { useState } from "react";
import { mutate } from "swr";
import TextInput from "~/components/form/TextInput";
import * as Form from "@radix-ui/react-form";
import { toast } from "react-toastify";

type EditConversionDialogProps = {
	open: boolean;
	onOpenChange: (open: boolean) => void;
	conversion: any; // Replace 'any' with your conversion type
	onSuccess: () => void;
};

function EditConversionDialog({ 
	open, 
	onOpenChange, 
	conversion, 
	onSuccess 
}: EditConversionDialogProps) {
	const [amount, setAmount] = useState(conversion?.attribution?.proceeds_amount_gross || "");

	const handleEdit = async (evt: React.FormEvent<HTMLFormElement>) => {
		evt.preventDefault();
		console.log(amount);
		apiClient.put(`/convert/attributions/${conversion.attribution.id}`, {
			proceeds_amount_gross: amount,
		}).then(() => {
			onSuccess();
			onOpenChange(false);
		}).catch((error) => {
			console.error("Failed to update conversion:", error);
		});
	};

	return (
		<Dialog.Root open={open} onOpenChange={onOpenChange}>
			<Dialog.Content>
				<Dialog.Title>Edit Conversion Amount</Dialog.Title>
				<Form.Root onSubmit={handleEdit} >
					<div className="py-4">
						<TextInput
							name="proceeds_amount_gross"
							label="Proceeds Amount Gross"
							value={amount}
							onChange={(value) => setAmount(value)}
							type="number"
						/>
					</div>
					<div className="flex justify-end gap-3">
						<Button variant="soft" onClick={() => onOpenChange(false)}>
							Cancel
						</Button>
						<Button type="submit">
							Save Changes
						</Button>
					</div>
				</Form.Root>
			</Dialog.Content>
		</Dialog.Root>
	);
}

export default function WorkflowRevenuePage() {
	const params = useParams();
	const [searchParams, setSearchParams] = useSearchParams();
	const page = parseInt(searchParams.get("page") || "1", 10);

	const { data: workflow, error: workflowError } = useSWR(
		`/convert/workflows/${params.id}`,
		url => apiClient.get(url).then(res => res.data)
	);

	const { data: revenueData, error: revenueError, mutate: mutateRevenue } = useSWR(
		`/convert/workflows/${params.id}/conversions?page=${page}`,
		url => apiClient.get(url).then(res => res.data)
	);

	const [selectedConversion, setSelectedConversion] = useState(null);
	const [isEditing, setIsEditing] = useState(false);

	if (!workflow || !revenueData) return null;

	const handlePageChange = (newPage: number) => {
		setSearchParams({ page: newPage.toString() });
	};

	return (
		<WorkflowLayout workflow={workflow}>
			<div className="px-10 space-y-4">
				<Table.Root variant="surface">
					<Table.Header>
						<Table.Row>
							<Table.ColumnHeaderCell>Customer Email</Table.ColumnHeaderCell>
							<Table.ColumnHeaderCell>Placement</Table.ColumnHeaderCell>
							<Table.ColumnHeaderCell>Variant</Table.ColumnHeaderCell>
							<Table.ColumnHeaderCell>Item Name</Table.ColumnHeaderCell>
							<Table.ColumnHeaderCell>Amount</Table.ColumnHeaderCell>
							<Table.ColumnHeaderCell>Completed At</Table.ColumnHeaderCell>
							<Table.ColumnHeaderCell>Excluded</Table.ColumnHeaderCell>
							<Table.ColumnHeaderCell>Actions</Table.ColumnHeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{revenueData.data.map((conversion) => (
							<Table.Row key={conversion.id}>
								<Table.Cell>{conversion.customer_email}</Table.Cell>
								<Table.Cell>{conversion.placement}</Table.Cell>
								<Table.Cell>{conversion.variant_name}</Table.Cell>
								<Table.Cell>{conversion.items[0]?.name || 'N/A'}</Table.Cell>

								{/* <Table.Cell>{conversion.items[0]?.amount_total_formatted || 'N/A'}</Table.Cell> */}
								<Table.Cell>{conversion.attribution?.proceeds_amount_gross || 'N/A'}</Table.Cell>

								<Table.Cell>{format(new Date(conversion.completed_at * 1000), 'yyyy-MM-dd HH:mm:ss')}</Table.Cell>
								<Table.Cell>{conversion.is_excluded ? 'Yes' : 'No'}</Table.Cell>
								<Table.Cell>
									{conversion.attribution && (
										<Button 
											size="1" 
											variant="soft" 
											onClick={() => {
												setSelectedConversion(conversion);
												setIsEditing(true);
											}}
										>
											Edit
										</Button>
									)}
									{!conversion.attribution && ('NA')}
								</Table.Cell>
							</Table.Row>
						))}
					</Table.Body>
				</Table.Root>

				{selectedConversion && (
					<EditConversionDialog
						open={isEditing}
						key={selectedConversion.id}
						onOpenChange={setIsEditing}
						conversion={selectedConversion}
						onSuccess={() => {
							mutateRevenue();
							toast.success('Conversion amount updated');
						}}
					/>
				)}

				<Pagination
					currentPage={revenueData.current_page}
					totalPages={Math.ceil(revenueData.total / revenueData.per_page)}
					onPageChange={handlePageChange}
				/>
			</div>
		</WorkflowLayout>
	);
}