import {format} from "date-fns";
import React from "react";


const DateComponent = ({ timestamp }) => {
  // Convert server timestamp (seconds) to milliseconds
  const date = new Date(timestamp * 1000);
  const formattedDate = format(date, 'PP');

  return <div className="italic">{formattedDate}</div>;
};

export default DateComponent;
