import {useParams} from "react-router";
import useSWR from "swr";
import {getFetcher} from "~/lib/apiClient";
import Debug from "~/components/Debug";
import PageHeader from "~/components/PageHeader";
import {Link} from "react-router-dom";
import {ChevronLeftIcon} from "@heroicons/react/16/solid";
import React, { useState } from "react";
import { Avatar, Button, Heading, Table } from "@radix-ui/themes";
import { avatarLetters } from "./CustomersListPage";
import { formatDateTime } from "~/utils/dateTime";
import { ExternalLinkIcon } from "lucide-react";

function ScheduleSummary({ subscriptions }) {
  return (
    <div>
      <Heading size="3">Subscriptions</Heading>
      {subscriptions.length === 0 ? (
        <div>No subscriptions found</div>
      ) : (
        <Table.Root>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeaderCell>Plan</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Status</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Currency</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Renewal</Table.ColumnHeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {subscriptions.map((subscription) => (
              <Table.Row key={subscription.id || subscription.plan?.id}>
                <Table.Cell>
                  {subscription.plan ? (
                    <Link to={`/catalog/pricing/plans/${subscription.plan.id}`} className="text-link">
                      {subscription.plan.name} 
                    </Link>
                  ) : (
                    'No plan found'
                  )}
                  &nbsp;({subscription.quantity})
                </Table.Cell>
                <Table.Cell>{subscription.current_state}</Table.Cell>
                <Table.Cell>{subscription.plan?.currency_code || '-'}</Table.Cell>
                <Table.Cell>{subscription.plan?.renew_interval || '-'}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table.Root>
      )}
    </div>
  )
}

export default function CustomerDetailPage() {

  const {customerId} = useParams();

  const { data: customer, error } = useSWR(`/customers/${customerId}`, getFetcher);
  const { data: customerEvents, error: customerEventsError } = useSWR(`/usage/events?customer=${customerId}`, getFetcher);
  const { data: usage, error: usageError } = useSWR(`/customers/${customerId}/usage`, getFetcher);

  const [isAdditionalDetailsVisible, setIsAdditionalDetailsVisible] = useState(false);

  console.log(customerEvents);

  if (!customer) return <div>Loading...</div>
  if (error) return <div>Error: {error}</div>

  return (
      <div className="p-10">
        <PageHeader
          navigation={<Link className="flex items-center" to={`/customers`}><ChevronLeftIcon className="w-6 h-6 text-black" /></Link>}
        >
          <Heading>Customer</Heading>
        </PageHeader>

        <div className="flex h-full">
          <div className="w-[400px] border-r bg-white">
            <div className="flex items-center p-4 gap-4">
              {/*<img src="/" className="w-10 h-10 rouned-full" alt=""/>*/}
              <Avatar
                  src={customer.hostname ? `https://logo-service.plandalf.dev/api/logo?url=${customer.hostname}` : null}
                  fallback={avatarLetters(customer)}
                  size={"1"}
                />
              <h1>{customer.name || customer.email}</h1>
            </div>

            <div className="divide-y">
              <div className="flex justify-between items-center px-4 h-12">
                <div className="font-semibold">Customer Since</div>
                <div>{customer.created_at}</div>
              </div>

              <div className="flex justify-between items-center px-4 h-12">
                <div className="font-semibold">Stripe Identifier</div>
                <div>
                  <Link target="_blank" to={customer.twin?.connector_link} className="text-link flex gap-1 items-center">
                    {customer.reference_id}
                    <ExternalLinkIcon className="w-4 h-4" />
                  </Link>
                </div>
              </div>

              <div className="flex justify-between items-center px-4 h-12">
                <div className="font-semibold">Email</div>
                <div>{customer.email}</div>
              </div>

              <div className="flex justify-between items-center px-4 h-12">
                <div className="font-semibold">Phone</div>
                <div>{customer.phone || "-"}</div>
              </div>
            </div>
          </div>

          <div className="flex-grow right space-y-4 px-4">

          <div>
          <Heading size="3">Usage</Heading>
            {usage && (
              <pre>{JSON.stringify(usage, null, 2)}</pre>
            )}
            {usageError && (
              <div>Usage Error: {usageError?.response?.data?.message || 'Error fetching usage'}</div>
            )}
          </div>

            {customer.subscriptions && (
              <ScheduleSummary subscriptions={customer.subscriptions}/>
            )}

            <Button 
            variant="ghost"
            onClick={() => setIsAdditionalDetailsVisible(!isAdditionalDetailsVisible)}
            >{isAdditionalDetailsVisible ? 'Hide Details' : 'Show Details'}</Button>
            {isAdditionalDetailsVisible && (
              <>
                <Heading size="3">Details</Heading>
                <Debug {...customer}/>
              </>
            )}
          </div>
        </div>

        <Heading size="3">Usage Events</Heading>
        <Table.Root>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeaderCell>Event</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Properties</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Timestamp</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Metadata</Table.ColumnHeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {customerEvents?.data.map((event) => (
              <Table.Row key={event.id}>
                <Table.Cell>
                  <div>Name:{event.event_name}</div>
                  <div>ID: {event.unique_id}</div>
                  </Table.Cell>
                <Table.Cell>
                  <pre>{JSON.stringify(event.properties, null, 2)}</pre>
                </Table.Cell>
                <Table.Cell>{formatDateTime(event.created_at)}</Table.Cell>
                <Table.Cell>
                  <pre>{event.metadata && JSON.stringify(event.metadata, null, 2)}</pre>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table.Root>
      </div>
  )
}
