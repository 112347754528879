import {Button, Heading, Callout} from "@radix-ui/themes";
import apiClient, {getFetcher} from "~/lib/apiClient";
import useSWR from "swr";
import * as Form from "@radix-ui/react-form";
import SelectInput from "~/components/form/SelectInput";
import {useMemo, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import NotImplementedWarning from "~/components/NotImplementedWarning";

export default function PlanCreatePage() {

  return (
    <div className="p-10">
      <NotImplementedWarning />
    </div>
  )
  
  const [query] = useSearchParams();

  const schemeId = query.get('scheme_id');
  const navigate = useNavigate();

  const { data: plans } = useSWR('/catalog/plans?limit=9999', getFetcher);
  const [data, setData] = useState({
    plan: null,
    scheme: query.get('scheme_id'),
    product: query.get('product_id'),
    currency: query.get('currency_code'),
    interval: query.get('renewal_interval'),
  });
  const [isSubmitting, setSubmitting] = useState(false);

  //?scheme_id=l@v4
  // &renewal_interval=P1M
  // &product_id=prod_NeDHvS07cyhD9n@v1
  // &currency_code=USD

  const filteredPlans = useMemo(() => {
    return plans?.data.filter(plan => {
      return plan.base_charge?.product.id === query.get('product_id')
        && plan.currency === query.get('currency_code')
        && plan.renew_interval === query.get('renewal_interval');
    });
  }, [plans, query])
  function handleSubmit(evt) {
    evt.preventDefault();
    setSubmitting(true);

    apiClient.post(`/pricing/schemes/${schemeId}/plans`, data)
      .then(() => {
        navigate(`/catalog/pricing/schemes/${schemeId}`);
      })
      .catch(e => {

      })
      .finally(() => {
        setSubmitting(false);
      });
  }



  if (!plans) return <div>loading.</div>

  return (
    <div className="space-y-2 p-4">

      <Heading>Add Plan</Heading>


      <Form.Root
        onSubmit={handleSubmit}
        className="space-y-4">
        <div>Link with Price</div>

        {filteredPlans.length === 0 && (
          <Callout.Root color="red">No plans found which match product, currency and interval</Callout.Root>
        )}

        <SelectInput
          disabled={filteredPlans.length === 0}
          options={filteredPlans.map(plan => ({
            name: `${plan.display_name} ${plan.base_charge.amount_formatted} ${plan.currency}`,
            id: plan.id,
          })) || []}
          onValueChange={value => setData({...data, plan: value})}
          label="Plan"
          name="plan"
          value={data.plan}
        />

        <Button loading={isSubmitting}>Create</Button>
      </Form.Root>
      <pre>{JSON.stringify(data, null, 2)}</pre>
    </div>
  )
}
