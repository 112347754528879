import {Form} from "@radix-ui/react-form";
import {useState} from "react";
import apiClient from "~/lib/apiClient";
import setIn from "lodash/fp/set";
import TextInput from "~/components/form/TextInput";
import {Button, Callout, Heading} from "@radix-ui/themes";
import Alert from "~/components/Alert";
import {useNavigate} from "react-router-dom";


export default function FeatureSetCreatePage() {

  const [errors, setErrors] = useState(null);
  const [data, setData] = useState({
      name: null,
  });
  const navigate = useNavigate();
  function handleSubmit(evt) {
      evt.preventDefault();
      apiClient.post(`/catalog/feature_sets`, data).then(res => {
        console.log("Feature Set created", res);

        navigate(`/catalog/feature_sets`);
      }).catch(err => {
          console.error("Failed to create feature set", );
          setErrors(err.response.data.errors || {
            message: err.response.data.message,
          });

      });
  }

  return (
    <div className="p-6">
      <Heading as="h1" size="6">Create a new Feature Set</Heading>
      <p>Use this form to create a new feature set.</p>
      {errors?.message && <Callout.Root size="2" color="red"><Callout.Text>{errors.message}</Callout.Text></Callout.Root>}

      <Form onSubmit={handleSubmit}>
        <TextInput
          name="name"
          label="Name"
          value={data.name}
          onChange={value => setData(setIn('name', value, data))}
          errors={errors?.name}
        />

        <Button type="submit">
            Create
        </Button>
      </Form>
    </div>
  );
}
