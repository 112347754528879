import AceEditor from "react-ace";
import React from "react";
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-monokai';

export default function JSONEditor({ jsonInput, setJsonInput, errors, rows = 10}) {
  return (
    <div>
      <AceEditor
        mode={"json"}
        theme={"monokai"}
        setOptions={{
          useWorker: false
        }}
        onChange={setJsonInput}
        value={jsonInput}
        name="jsonInput"
        id="jsonInput"
        width={'100%'}
        editorProps={{
          $blockScrolling: true,
        }}
        height={rows * 20 + 'px'}
      />
      {errors && <div className="text-red-500">{errors}</div>}
    </div>
  )
}
