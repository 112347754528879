import {useParams} from "react-router";
import PageHeader from "~/components/PageHeader";
import apiClient, {getFetcher} from "~/lib/apiClient";
import useSWR from "swr";
import Debug from "~/components/Debug";
import {get} from "lodash";
import React, {useEffect, useState} from "react";
import TextInput, { TextInputProps } from "~/components/form/TextInput";
import classNames from "classnames";
import {Link} from "react-router-dom";
import {ArrowLongLeftIcon, ChevronLeftIcon} from "@heroicons/react/16/solid";
import * as Form from "@radix-ui/react-form";
import SelectInput from "~/components/form/SelectInput";
import {Callout, Checkbox} from "@radix-ui/themes";

interface CheckboxGroupProps {
  label: string;
  options: { label: string; value: string }[];
  selectedValues: string[];
  onChange: (selectedValues: string[]) => void;
}

function CheckboxGroup({ label, options, selectedValues, onChange }: CheckboxGroupProps) {
  const handleChange = (value: string, checked: boolean) => {
    const newSelectedValues = checked
      ? [...selectedValues, value]
      : selectedValues.filter(v => v !== value);
    onChange(newSelectedValues);
  };

  return (
    <div>
      <label className="block text-sm font-medium text-gray-700 mb-2">{label}</label>
      <div className="space-y-2">
        {options.map(option => (
          <div key={option.value} className="flex items-center">
            <Checkbox
              id={option.value}
              checked={selectedValues.includes(option.value)}
              onCheckedChange={(checked) => handleChange(option.value, checked as boolean)}
              className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
            />
            <label htmlFor={option.value} className="ml-2 block text-sm text-gray-900">
              {option.label}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
}


interface Client {
  id: string;
  name: string;
  billing_provider_id: string | null;
  exclusion_rules: { rule: string; value: string }[] | null;
  allowed_origins: string[] | null;
  pending_origins: string[] | null;
  // ... other fields
}

interface FormData {
  name: string | null;
  billing_provider_id: string | null;
  exclusion_rules: string;
  allowed_origins: string[];
}

export default function ClientDetailPage() {
  const { clientId } = useParams<{ clientId: string }>();

  const { data: client, error } = useSWR<Client>(`/clients/${clientId}`, getFetcher);
  const { data: billingProviders, error: billingProvidersError } = useSWR(`/billing/providers`, getFetcher);

  const [data, setData] = useState<FormData>({
    name: null,
    billing_provider_id: null,
    exclusion_rules: "",
    allowed_origins: [],
  });
  const [errors, setErrors] = useState<Record<string, string[]>>({});
  const [isLoading, setIsLoading] = useState(false);
  const [removedOrigins, setRemovedOrigins] = useState<string[]>([]);

  useEffect(() => {
    if (!client) return;
    setData({
      name: client.name,
      billing_provider_id: client.billing_provider_id,
      exclusion_rules: client.exclusion_rules
        ? client.exclusion_rules.map(rule => rule.value).join(",")
        : "",
      allowed_origins: client.allowed_origins || [],
    });
  }, [client]);

  function handleSubmit(evt: React.FormEvent<HTMLFormElement>) {
    evt.preventDefault();
    setIsLoading(true);
    setErrors({});

    const formattedData = {
      ...data,
      exclusion_rules: data.exclusion_rules.split(",").map(domain => ({
        rule: "emailDomainIs",
        value: domain.trim(),
      })),
    };

    apiClient.patch(`/clients/${clientId}`, formattedData)
      .then(res => {
        console.log("Client updated", res);
        setIsLoading(false);
        setRemovedOrigins([]);
      })
      .catch(err => {
        setIsLoading(false);
        if (err.response && err.response.data && err.response.data.errors) {
          setErrors(err.response.data.errors);
        } else {
          console.error("Failed to update client", err);
        }
      });
  }

  function field(id, label): TextInputProps {
    return {
      id,
      label,
      value: get(data, id),
      onChange: (value) => setData({...data, [id]: value}),
      error: getFieldError(id),
    }
  }

  function getFieldError(fieldName: string) {
    const errorMessages = Object.entries(errors)
      .filter(([key, value]) => key.startsWith(fieldName))
      .map(([key, value]) => value)
      .flat();
    return errorMessages.length > 0 ? errorMessages.join(", ") : null;
  }

  function handleAllowedOriginsChange(selectedOrigins: string[]) {
    const newRemovedOrigins = (client?.allowed_origins || []).filter(
      origin => !selectedOrigins.includes(origin)
    );
    setRemovedOrigins(newRemovedOrigins);
    setData({ ...data, allowed_origins: selectedOrigins });
  }

  if (!client) return <div>Loading...</div>;
  if (error) return <div>Error loading client: {error}</div>;

  const allOrigins = [...(client.allowed_origins || []), ...(client.pending_origins || [])].filter((value, index, self) => self.indexOf(value) === index);

  return (
    <div>
      <PageHeader navigation={<Link className="flex items-center" to={`/settings/developers`}><ChevronLeftIcon className="w-6 h-6 text-black" /></Link>}>
        <h1 className="text-xl font-semibold">{client.name}</h1>
      </PageHeader>

      <Form.Root onSubmit={handleSubmit} className="p-6 space-y-6">
        <TextInput {...field('name', 'Name')} />

        {billingProviders && billingProviders.data.length > 0 && (
          <SelectInput
            options={billingProviders.data}
            {...field('billing_provider_id', 'Billing Provider')}
          />
        )}
        {billingProviders && billingProviders.data.length === 0 && <Callout.Root color="orange">No billing providers available</Callout.Root>}
        {billingProvidersError && <div>Error loading billing providers: {billingProvidersError}</div>}

        <TextInput
          {...field('exclusion_rules', 'Exclusion Rules')}
          placeholder="Enter email domains separated by commas (e.g., example.com, test.com)"
        />

        <CheckboxGroup
          label="Allowed Origins"
          options={allOrigins.map(origin => ({ label: origin, value: origin }))}
          selectedValues={data.allowed_origins}
          onChange={handleAllowedOriginsChange}
        />

        {removedOrigins.length > 0 && (
          <Callout.Root color="orange">
            Warning: The following origins will be removed and not added back to pending:
            {removedOrigins.join(", ")}
          </Callout.Root>
        )}

        <div className="flex items-center justify-between">
          <button
            className={classNames({
              "bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline": true,
              "opacity-50 cursor-not-allowed": isLoading,
            })}
            type="submit"
          >
            {isLoading ? "Saving..." : "Save"}
          </button>
        </div>

        <div>
          <p><b>Client ID</b>: {clientId}</p>
          <p><b>Client Secret</b>: {client.secret}</p>
        </div>
        <Debug {...client}/>
      </Form.Root>

    </div>
  );
}
