import useSWR from "swr";
import apiClient, {getFetcher} from "~/lib/apiClient";
import React, {useEffect, useState} from "react";
import {get} from "lodash";
import setIn from 'lodash/fp/set';
import Alert from "~/components/Alert";
import SelectInput from "~/components/form/SelectInput";
import TextInput, { ValueFormatter } from "~/components/form/TextInput";
import {Form} from "@radix-ui/react-form";
import {
  Button,
  Flex,
  RadioGroup,
  Link,
  TextField,
  Popover,
  Avatar,
  Box,
  TextArea,
  Checkbox,
  Text,
  Callout, Heading, Select
} from "@radix-ui/themes";
import update from "immutability-helper";
import {useNavigate} from "react-router-dom";
import PageHeader from "~/components/PageHeader";
import { Checkbox } from "@radix-ui/themes";

function DateInput({id, label, value, onChange}) {
  return (
    <div className="form-group">
      <label htmlFor={id}>{label}</label>
      <input className="form-input" type="date" id={id} value={value} onChange={onChange}/>
    </div>
  )
}

export default function FeatureCreatePage() {

  const navigate = useNavigate();
  const [errors, setErrors] = useState(null);
  const [hasChangedSlug, setHasChangedSlug] = useState(false);
  const [data, setData] = useState({
    name: null,
    lookup_key: '',
    feature_set_id: null,
    metric: {
      aggregation: 'LATEST',
      type: 'persistent',
    }
  });
  const [includeMetric, setIncludeMetric] = useState(false);

  const { data: featureSets } = useSWR('/catalog/feature_sets', getFetcher);

  // Set the first feature set as active on load
  useEffect(() => {
    if (featureSets?.data && featureSets.data.length > 0 && !data.feature_set_id) {
      setData(prevData => ({...prevData, feature_set_id: featureSets.data[0].id}));
    }
  }, [featureSets]);

  function handleSubmit(evt) {
    evt.preventDefault();
    const submissionData = includeMetric ? data : { ...data, metric: undefined };
    apiClient.post(`/catalog/features`, submissionData).then(res => {
      console.log("Feature created", res);
      navigate('/catalog/features');
    }).catch(err => {
      console.error("Failed to create feature", err);
      setErrors(err.response.data.errors || {
        message: err.response.data.message,
      });
    });
  }

  function handleAutoSlug(value) {
    if (hasChangedSlug) return;
    const slugifiedValue = slugifyWithUnderscores(value);
    if (!slugifiedValue || slugifiedValue.length === 0) return;
    setData(setIn('lookup_key', slugifiedValue, data));
  }

  useEffect(() => {
    if (hasChangedSlug) return;
    if (!data.name) return;
    handleAutoSlug(data.name);
  }, [data.name, hasChangedSlug])

  function field(id: string, label) {
    return {
      id,
      label,
      value: get(data, id),
      errors: get(errors, id),
      onChange: (value) => {
        if (id === 'lookup_key' && hasChangedSlug === false)  {
          setHasChangedSlug(true);
        }
        setData(setIn(id, value, data));
      },
    }
  }

  function handleFsChange(value) {
    setData(setIn('feature_set_id', value, data));
  }

  function slugifyWithUnderscores(value: string) {
    return value
      .toLowerCase()
      .replace(/\s+/g, '_')
      .replace(/[^a-z0-9_]/g, '')
      .replace(/_{2,}/g, '_')
      .replace(/^_|_$/g, '');
  }

  return (
    <div className="p-10">
      <PageHeader>
        <Heading >Create Feature</Heading>
      </PageHeader>
      <div className="space-y-6">
        <Form onSubmit={handleSubmit} className="space-y-2">
          {errors?.message &&
            <Callout.Root size="2" color="red"><Callout.Text>{errors.message}</Callout.Text></Callout.Root>}

            <TextInput
              placeholder="Feature Name"
              {...field('name', 'Name')}
            />

            <TextInput
              placeholder="lookup-key"
              beforeChangeFormatter={ValueFormatter.slug}
              {...field('lookup_key', 'Lookup Key')}
            />

            <div className="mb-2">
              <label className="block text-sm font-semibold mb-2" htmlFor="example">
                Feature Set
                {/*{validationError && <span className="ml-2 text-red-700 text-xs">*{validationError}</span>}*/}
              </label>
              <RadioGroup.Root 
                defaultValue="1" 
                name="example" 
                variant="surface" 
                value={data.feature_set_id}
                onValueChange={handleFsChange}
              >
                {featureSets?.data.map((fs) => (
                  <RadioGroup.Item key={fs.id} value={fs.id}>{fs.name}</RadioGroup.Item>
                ))}
              </RadioGroup.Root>
              {featureSets?.data.length === 0 && (
                <Alert>No feature sets available. <Link href="/catalog/feature_sets/create">Create one</Link></Alert>
              )}
              {errors?.feature_set_id && (<div className="text-sm mt-2 text-rose-600">{errors.feature_set_id[0]}</div>)}
          </div>

          <Flex align="center" gap="2" className="py-4">
            <Checkbox checked={includeMetric} onCheckedChange={setIncludeMetric} id="includeMetric" />
            <label htmlFor="includeMetric" className="select-none">Include Metric</label>
          </Flex>

          {includeMetric && (
            <div className="border p-4">
              <Heading as="h3" size="4">Add Metric</Heading>

              <TextInput
                {...field('metric.event_name', 'Event Name')}
              />
              <SelectInput
                {...field('metric.aggregation', 'Aggregation')}
                options={[
                  {id: 'LATEST', name: 'Latest'},
                ]}
              />
              <SelectInput {...field('metric.type', 'Type')} options={[
                {id: 'persistent', name: 'Persistent'},
                {id: 'transient', name: 'Transient'},
              ]}/>
              <TextInput {...field('metric.field_name', 'Field Name')} />
            </div>
          )}

          <Flex justify="between">

            <Flex justify="end" gap="3" align="center">
              <Button variant="solid" type="submit" radius="large">Create</Button>
            </Flex>
          </Flex>

          {/*<button type="submit" className="btn">Submit</button>*/}


          {/*<pre>{JSON.stringify(featureSets, null, 2)}</pre>*/}
          {/*<DateInput {...field('released_at', "Released At")} />*/}

          <div className="hidden border p-4 space-y-4">
            <h3>Metric Setup</h3>

            <TextInput {...field('metric.key', 'Metric Key')} />

            <SelectInput {...field('metric.aggregation', 'Aggregation')} options={[
              {id: 'LATEST', name: 'Latest'},
            ]}/>

            <SelectInput {...field('metric.type', 'Type')} options={[
              {id: 'persistent', name: 'Persistent'},
              {id: 'transient', name: 'Transient'},
            ]}/>

            <TextInput {...field('metric.field_name', 'Field Name')} />
          </div>

        </Form>

        {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
        {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
      </div>

    </div>
  )
}
