import { Radio } from "@radix-ui/themes";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import AnchorButton from "~/components/AnchorButton";
import TextInput from "~/components/form/TextInput";
import apiClient, { getFetcher } from "~/lib/apiClient";
import * as Form from "@radix-ui/react-form";

export function PaywallViewPage() {
	const { paywallId } = useParams();
	const { data: paywall, error: paywallError } = useSWR(`/convert/paywalls/${paywallId}`, getFetcher);
	const { data: clients, error: clientsError } = useSWR("/clients", getFetcher);
	const [token, setToken] = useState(null);
	const [selectedOrigin, setSelectedOrigin] = useState("");
	const [customPath, setCustomPath] = useState(() => {
		return localStorage.getItem('customPath') || "/chat";
	});

	// get workflow
	useEffect(() => {
		if (!paywall) return;
		apiClient.post(`/sanctum/token`)
			.then(r => {
				setToken(r.data);
			})
	}, [paywall]);

	useEffect(() => {
		localStorage.setItem('customPath', customPath);
	}, [customPath]);

	if (!paywall || !token || !clients) {
		return <div>Loading...</div>;
	}

	const v = {
		token,
		action: {
			type: 'showPaywall',
			paywallId: paywall?.id,
		}
	};

	const params = new URLSearchParams({
		__plandalf: btoa(JSON.stringify(v))
	});

	const compiledUrl = `${selectedOrigin}${customPath}#?${params.toString()}`;

	return (
		<Form.Root className="p-10 mt-20">
			<h2 className="text-2xl font-bold mb-4">Select a Client and Origin</h2>
			{clients?.data.map((client) => (
				<div key={client.id} className="mb-6">
					<h3 className="text-xl mb-2 font-semibold">{client.name}</h3>
          {!client.allowed_origins?.length && <div className="bg-orange-50 font-semibold p-4 text-orange-500">No origins available for this client.</div>}
          {client.allowed_origins?.length && (
            <div className="border bg-gray-50 p-4">
              {client.allowed_origins?.map((origin) => (
                <label key={origin} className="flex gap-2 items-center">
                  <Radio
                    name="origin"
                    value={origin}
                    checked={selectedOrigin === origin}
                    onValueChange={setSelectedOrigin}
                    
                  />
                  {origin}
                </label>
              ))}
            </div>
          )}
				</div>
			))}

			<div className="mb-4">
				<label className="block">
					Custom Path:
					<TextInput
						type="text"
						value={customPath}
						onChange={setCustomPath}
						className="ml-2 border rounded px-2 py-1"
					/>
				</label>
			</div>

			{selectedOrigin && (
				<div>
					<h3 className="text-lg font-semibold mb-2">Generated URL:</h3>
					<p className="mb-2">{compiledUrl}</p>
				</div>

        )}
        <AnchorButton href={selectedOrigin ? compiledUrl : 'javascript: void(0)'} target={selectedOrigin ? '_blank' : undefined} disabled={!selectedOrigin}>
          Start Paywall
        </AnchorButton>
		</Form.Root>
	);
}