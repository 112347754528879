import { usePlandalf } from "@plandalf/react-plandalf-js";
import { Button } from "@radix-ui/themes";



export default function TestPage() {


    const p = usePlandalf();

    const handleClick = () => {
        console.log("clicked")
        
        try {
            p.plandalf.dispatch("TestEvent");
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <div className="p-12">
            <Button onClick={handleClick}>Trigger TestEvent</Button>
        </div>
    )
}