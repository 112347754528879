import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import * as Form from '@radix-ui/react-form';
import { Button } from '@radix-ui/themes';
import { toast } from 'react-toastify';
import apiClient, { getFetcher } from '~/lib/apiClient';
import TextInput from '~/components/form/TextInput';
import CodeInput from '~/components/form/CodeInput';
import SelectInput from '~/components/form/SelectInput';
import { ElementDataContainer } from './ElementsListPage';
import JSONEditor from '~/components/JSONEditor';
import JSONInput from '~/components/form/JSONInput';
import { get } from 'lodash';

const ElementDetailPage: React.FC = () => {
  const { elementId } = useParams<{ elementId: string }>();
  const { data: elementData, error, mutate } = useSWR<ElementDataContainer>(
    `/convert/elements/${elementId}`,
    getFetcher
  );

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [data, setData] = useState<ElementDataContainer | null>(null);

  useEffect(() => {
    if (elementData) {
      setData({...elementData, lookup_key: elementData.id});
    }
  }, [elementData]);

  if (error) return <div>Failed to load element</div>;
  if (!data) return <div>Loading...</div>;

  const field = (name: keyof ElementDataContainer, label: string) => ({
    name,
    label,
    value: get(data, name),
    errors: get(errors, name),
    onChange: (value: string) => {
      setData({ ...data, [name]: value });
    },
  });

  const handleSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    setIsSubmitting(true);
    setErrors(null);

    apiClient
      .put(`/convert/elements/${elementId}`, data)
      .then(() => {
        toast.success('Element updated');
        mutate();
      })
      .catch((err) => {
        setErrors(err.response.data.errors || { general: err.response.data.message });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <div className="p-10">
      <h1>Edit Element</h1>
      <Form.Root onSubmit={handleSubmit}>
        <div className="mb-4">
          <TextInput {...field('display_name', 'Display Name')} />
          <TextInput {...field('lookup_key', 'Lookup Key')} />
          {/* <SelectInput
            options={[
              { name: 'Gate', id: 'gate' },
              { name: 'Banner', id: 'banner' },
            ]}
            {...field('type', 'Type')}
          /> */}
          <SelectInput
            options={[
              { name: 'Draft', id: 'draft' },
              { name: 'Active', id: 'active' },
              { name: 'Inactive', id: 'inactive' },
              { name: 'Archived', id: 'archived' },
            ]}
            {...field('current_state', 'Current State')}
          />
          <JSONInput
            {...field('conditions', 'Conditions')}
          />

          <JSONInput
            {...field('event_handlers', 'Event Handlers')}
          />
          <SelectInput
            options={[
              { name: 'Manual', id: 'manual' },
              { name: 'Automatic', id: 'automatic' },
            ]}
            {...field('insertion_type', 'Insertion Type')}
          />

          <JSONInput
            {...field('insertion_rules', 'Insertion Rules')}
          />
        </div>
        <Button type="submit" disabled={isSubmitting}>
          Update Element
        </Button>
      </Form.Root>
      {errors?.general && <div className="text-red-500 mt-2">{errors.general}</div>}
    </div>
  );
};

export default ElementDetailPage;