import { useState, useEffect } from "react";
import useSWR from "swr";
import apiClient, { getFetcher } from "~/lib/apiClient";
import { Button, Dialog, Flex, Heading } from "@radix-ui/themes";
import { useParams, useNavigate } from "react-router-dom";
import TextInput from "~/components/form/TextInput";
import { toast } from 'react-toastify';
import * as Form from "@radix-ui/react-form";
import get from "lodash/get";
import type { TextInputProps } from "~/components/form/TextInput";
import SelectInput from "~/components/form/SelectInput";

interface Metric {
  id: string;
  name: string;
  description: string;
  event_name: string;
  field_name: string;
  type: string;
  aggregation: string;
  created_at: number;
  updated_at: number;
}

export default function MetricEditPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState([]);
  
  const { data: metric, error: metricError } = useSWR<Metric>(
    `/usage/metrics/${id}`,
    getFetcher
  );

  const [formData, setFormData] = useState<Partial<Metric>>({
    name: "",
    description: "",
    code: "",
    event_name: "",
    field_name: "",
    type: "persistent",
    aggregation: "LATEST",
  });

  useEffect(() => {
    if (metric) {
      setFormData({
        name: metric.name,
        description: metric.description,
        event_name: metric.event_name,
        field_name: metric.field_name,
        type: metric.type,
        aggregation: metric.aggregation,
      });
    }
  }, [metric]);

  function field(id: string, label: string): TextInputProps {
    return {
      id,
      label,
      value: get(formData, id),
      onChange: (value) => setFormData({ ...formData, [id]: value }),
      errors: get(errors, id),
    }
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    apiClient.put(`/usage/metrics/${id}`, formData)
      .then(res => {
        toast.success("Metric updated successfully");
        navigate("/catalog/metrics");
      })
      .catch(err => {
        console.error("Failed to update metric:", err);
        setErrors(err.response.data.errors || {
          message: err.response.data.message,
        });
        toast.error("Failed to update metric");
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleDelete = async () => {
    try {
      await apiClient.delete(`/usage/metrics/${id}`);
      toast.success("Metric deleted successfully");
      navigate("/catalog/metrics");
    } catch (error) {
      console.error("Failed to delete metric:", error);
      toast.error("Failed to delete metric");
    }
  };

  if (metricError) return <div>Failed to load</div>;
  if (!metric) return <div>Loading...</div>;

  return (
    <div className="p-6">
      <Flex justify="between" align="center" mb="4">
        <Heading as="h1" size="6">Edit Metric</Heading>
        <Button color="red" variant="soft" onClick={() => setIsDeleteDialogOpen(true)}>
          Delete Metric
        </Button>
      </Flex>

      {metric && (
        <>
          <Form.Root onSubmit={handleSubmit} className="space-y-4 mb-8">
  
            <TextInput
              {...field('description', 'Description')}
              placeholder="Description"
            />

            <TextInput
              {...field('event_name', 'Event Name')}
              placeholder="Event Name"
            />

            <SelectInput
              {...field('aggregation', 'Aggregation')}
              options={[
                { id: 'LATEST', name: 'Latest' },
                { id: 'SUM', name: 'Sum' },
                { id: 'COUNT', name: 'Count' },
                { id: 'MAX', name: 'Max' },
                { id: 'MIN', name: 'Min' },
              ]}
            />

            <SelectInput
              {...field('type', 'Type')}
              options={[
                { id: 'persistent', name: 'Persistent' },
                { id: 'transient', name: 'Transient' },
              ]}
            />

            <TextInput
              {...field('field_name', 'Field Name')}
              placeholder="Field Name"
            />

            <Button type="submit" disabled={isSubmitting}>
              {isSubmitting ? "Saving..." : "Save Changes"}
            </Button>
          </Form.Root>

          <Dialog.Root open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
            <Dialog.Content>
              <Dialog.Title>Delete Metric</Dialog.Title>
              <Dialog.Description>
                Are you sure you want to delete this metric? This action cannot be undone.
              </Dialog.Description>

              <Flex gap="3" mt="4" justify="end">
                <Dialog.Close>
                  <Button variant="soft" color="gray">
                    Cancel
                  </Button>
                </Dialog.Close>
                <Button color="red" onClick={handleDelete}>
                  Delete
                </Button>
              </Flex>
            </Dialog.Content>
          </Dialog.Root>
        </>
      )}
    </div>
  );
} 