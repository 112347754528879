import * as Form from "@radix-ui/react-form";
import {TextField} from "@radix-ui/themes";
import React from "react";
import {capitalize} from "lodash";

export default function ColorInput(props) {
  const {
    value,
    name,
    label = capitalize(props.name),
    placeholder,
    helpText,
    validationError,
    onChange,
    errors,
    beforeChangeFormatter
  } = props;

  function handleChange(evt) {
    onChange(evt.target.value);
  }

  return (
    <Form.Field name={name} >
      <Form.Label className="FormLabel">
        {label}
        {validationError && <span className="ml-2 text-red-700 text-xs">*{validationError}</span>}
      </Form.Label>

      <TextField.Root
        variant="surface"
        id="name"
        type="color"
        {...props}
        onChange={handleChange}
        value={value || ''}
        placeholder={value}
      >
        <TextField.Slot>
          <div className="w-4 h-4 " style={{ background: value }}>

          </div>
        </TextField.Slot>
      </TextField.Root>

      {errors && (<div className="text-sm mt-2 text-rose-600">{errors[0]}</div>)}
      {helpText && <p className="text-gray-400 text-xs pt-1">{helpText}</p>}
    </Form.Field>
  )
}
