import useSWR from 'swr'
import apiClient from '~/lib/apiClient'
import {useEffect} from 'react'
import {useLocation, useNavigate} from "react-router-dom";
import { User } from '~/types';
// import { useParams, useRouter } from 'next/navigation'

interface AuthParams {
  middleware?: 'auth' | 'guest'
  redirectIfAuthenticated?: string
}



export const useAuth = ({ middleware = 'auth', redirectIfAuthenticated }: AuthParams = {}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { data: user, error, mutate } = useSWR('/user',  async() => {
    try {
      const response = await apiClient.get<User>('/user');
      return response.data;
    } catch (err) {
      console.error(err);
      if (err.response?.status === 401 && !location.pathname.toLowerCase().startsWith("/auth")) {
        // navigate('/auth/login');
        console.log("F");
      }
      if (err.response?.status !== 409) throw err
    }
  });

  const csrf = () => apiClient.get('/sanctum/csrf-cookie')

  const register = async ({ setErrors, ...props }) => {
    await csrf()

    setErrors([])

    apiClient
      .post('/auth/register', props)
      .then(() => mutate())
      .catch(error => {
        if (error.response.status !== 422) throw error

        setErrors(error.response.data.errors)
      })
  }

  const login = async ({ setErrors, setStatus, ...props }) => {
    try {
      await csrf()
    } catch (e) {
      console.log('error', e)
      setErrors({message: 'Server error' });
      return;
    }

    setErrors([])
    setStatus(null)

    apiClient
      .post('/auth/login', props)
      .then(() => mutate())
      .catch(err => {
        // console.error(err)
        if (err.response.status !== 422) {
          console.log('oof');
          setErrors({message: 'Something went wrong' })
          throw err
        }

        setErrors(err.response.data.errors)
      })
  }

  const forgotPassword = async ({ setErrors, setStatus, email }) => {
    await csrf()

    setErrors([])
    setStatus(null)

    apiClient
      .post('/forgot-password', { email })
      .then(response => setStatus(response.data.status))
      .catch(error => {
        if (error.response.status !== 422) throw error

        setErrors(error.response.data.errors)
      })
  }

  const resetPassword = async ({ setErrors, setStatus, ...props }) => {
    await csrf()

    setErrors([])
    setStatus(null)

    // axios
    //   .post('/reset-password', { token: params.token, ...props })
    //   .then(response =>
    //     // router.push('/login?reset=' + btoa(response.data.status)),
    //   )
    //   .catch(error => {
    //     if (error.response.status !== 422) throw error
    //
    //     setErrors(error.response.data.errors)
    //   })
  }

  const resendEmailVerification = ({ setStatus }) => {
    apiClient
      .post('/email/verification-notification')
      .then(response => setStatus(response.data.status))
  }

  const logout = async () => {
    if (!error) {
      await apiClient.post('/auth/logout').then(() => mutate())
    }

    if (window.location.pathname.toLowerCase().includes("/auth/login")) return;
    // console.log("LOGOUT");
    navigate('/auth/login')
  }

  useEffect(() => {
    if (middleware === 'guest' && redirectIfAuthenticated && user) {
      navigate(redirectIfAuthenticated)
    }

    if (
      window.location.pathname === '/verify-email' &&
      user.email_verified_at
    ) {
      navigate(redirectIfAuthenticated)
    }

    if (middleware === 'auth' && error) {
      logout()
    }
  }, [user, error])

  return {
    user,
    register,
    login,
    // forgotPassword,
    // resetPassword,
    // resendEmailVerification,
    logout,
  }
}
