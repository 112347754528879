import {Badge} from "@radix-ui/themes";
import React from "react";

export function PlanStatusBadge({ status }) {
  switch (status) {
    case 'active': return <Badge color="green">Active</Badge>
    case 'archived': return <Badge color="orange">Archived</Badge>
    default: return <Badge color="gray">{status}</Badge>
  }
}
