import DashboardLayout from "~/layouts/DashboardLayout";
import {NavLink, Outlet, useLocation, useOutlet} from "react-router-dom";
import React, {ContextType, createContext, useContext, useEffect, useState} from "react";
import PageHeader from "~/components/PageHeader";
import {CursorArrowRaysIcon} from "@heroicons/react/24/outline";
import cx from "classnames";
import {Heading} from "@radix-ui/themes";



function NavItem({ to, children, ...rest }) {
  return (
    <NavLink to={to} className={cx({
      "block whitespace-nowrap border-b-[3px] pt-2 pb-3 text-sm font-medium focus:outline-none text-gray-500 dark:text-gray-400 border-transparent hover:border-gray-200 dark:hover:border-gray-700 focus:border-gray-200 dark:focus:border-gray-700": true,
      className: rest.className,
    })}>
      {children}
    </NavLink>
  )
}

export default function CatalogLayout() {

  const [navigationContent, setNavigationContent] = useState(null);
  const location = useLocation();

  useEffect(() => {
    setNavigationContent(null);
  }, []);

  return (
    <div>
      {/*<PageHeader>*/}
      {/*  <Heading>Catalog</Heading>*/}
      {/*</PageHeader>*/}

      <Outlet context={{ setNavigationContent }} />
    </div>
  )
}
