import { useState, useEffect } from "react";
import useSWR from "swr";
import apiClient, { getFetcher } from "~/lib/apiClient";
import { Button, Dialog, Flex, Heading, Table, Text } from "@radix-ui/themes";
import { useParams, useNavigate } from "react-router-dom";
import TextInput from "~/components/form/TextInput";
import { toast } from 'react-toastify';
import * as Form from "@radix-ui/react-form";
import get from "lodash/get";

interface FeatureSet {
  id: string;
  name: string;
  description: string;
  created_at: number;
  updated_at: number;
}

interface Feature {
  id: string;
  name: string;
  description: string;
  feature_set_id: string;
}

export default function FeatureSetEditPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState([]);
  
  const { data: featureSet, error: featureSetError } = useSWR<FeatureSet>(
    `/catalog/feature_sets/${id}`,
    getFetcher
  );
  
  const { data: features, error: featuresError } = useSWR<Feature[]>(
    `/catalog/feature_sets/${id}/features`,
    getFetcher
  );

  const [formData, setFormData] = useState<Partial<FeatureSet>>({
    name: featureSet?.name || "",
    description: featureSet?.description || "",
  });

  useEffect(() => {
    if (featureSet) {
      setFormData({
        name: featureSet.name,
        description: featureSet.description,
      });
    }
  }, [featureSet]);

  function field(id, label): TextInputProps {
    return {
      id,
      label,
      value: get(formData, id),
      onChange: (value) => setFormData({ ...formData, [id]: value }),
      errors: get(errors, id),
    }
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    apiClient.put(`/catalog/feature_sets/${id}`, formData)
      .then(res => {
        toast.success("Feature set updated successfully");
        navigate("/catalog/feature_sets");
      })
      .catch(err => {
        console.error("Failed to update feature set:", err);
        setErrors(err.response.data.errors || {
          message: err.response.data.message,
        });
        toast.error("Failed to update feature set");
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleDelete = async () => {
    try {
      const response = await fetch(`/api/catalog/feature_sets/${id}`, {
        method: "DELETE",
      });
      if (response.ok) {
        toast.success("Feature set deleted successfully");
        navigate("/catalog/feature_sets");
      } else {
        toast.error("Failed to delete feature set");
      }
    } catch (error) {
      console.error("Failed to delete feature set:", error);
      toast.error("Failed to delete feature set");
    }
  };

  if (featureSetError || featuresError) return <div>Failed to load</div>;
  if (!featureSet) return <div>Loading...</div>;

  return (
    <div className="p-6">
      <Flex justify="between" align="center" mb="4">
        <Heading as="h1" size="6">Edit Feature Set</Heading>
        <Button color="red" variant="soft" onClick={() => setIsDeleteDialogOpen(true)}>
          Delete Feature Set
        </Button>
      </Flex>

      <Form.Root onSubmit={handleSubmit} className="space-y-4 mb-8">
        <TextInput
          {...field('name', 'Name')}
          placeholder="Feature Set Name"
        />

        <TextInput
          {...field('description', 'Description')}
          placeholder="Description"
        />

        <Button type="submit" disabled={isSubmitting}>
          {isSubmitting ? "Saving..." : "Save Changes"}
        </Button>
      </Form.Root>

      <Heading as="h2" size="5" mb="4">Features in this Set</Heading>
      
      {features && features.length > 0 ? (
        <Table.Root>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeaderCell>Name</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Description</Table.ColumnHeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {features.map((feature) => (
              <Table.Row key={feature.id}>
                <Table.RowHeaderCell>{feature.name}</Table.RowHeaderCell>
                <Table.Cell>{feature.description || '-'}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table.Root>
      ) : (
        <Text color="gray">No features found in this set</Text>
      )}

      <Dialog.Root open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
        <Dialog.Content>
          <Dialog.Title>Delete Feature Set</Dialog.Title>
          <Dialog.Description>
            Are you sure you want to delete this feature set? This action cannot be undone.
          </Dialog.Description>

          <Flex gap="3" mt="4" justify="end">
            <Dialog.Close>
              <Button variant="soft" color="gray">
                Cancel
              </Button>
            </Dialog.Close>
            <Button color="red" onClick={handleDelete}>
              Delete
            </Button>
          </Flex>
        </Dialog.Content>
      </Dialog.Root>
    </div>
  );
} 