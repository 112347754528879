import {Button} from "@radix-ui/themes";
import { Link } from "react-router-dom";

export default function AnchorButton({ href, children, ...otherProps }) {

  return (
    <Button asChild={true} {...otherProps}>
      <Link to={href}  {...otherProps}>{children}</Link>
    </Button>
  )
}
