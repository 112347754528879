import useSWR from "swr";
import { getFetcher } from "~/lib/apiClient";
import { Table, Link, Heading } from "@radix-ui/themes";

interface FeatureSetCollection {
  data: FeatureSet[];
}

interface FeatureSet {
  id: string;
  name: string;
  description: string;
  created_at: number;
  updated_at: number;
}

export default function FeatureSetListPage() {
  const { data: collection, error } = useSWR<FeatureSetCollection>('/catalog/feature_sets', getFetcher);

  return (
    <div className="p-6">
      {error && <div>Failed to load</div>}
      {!collection && <div>Loading...</div>}
      {collection?.data.length === 0 && (
        <div className="text-center text-gray-500">No feature sets found</div>
      )}

      <Heading as="h1" size="6">Feature Sets</Heading>

      {collection && (
        <Table.Root>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeaderCell>Name</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Description</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Created</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Updated</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Edit</Table.ColumnHeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {collection.data.map(featureSet => (
              <Table.Row key={featureSet.id}>
                <Table.RowHeaderCell>
                  <Link color="gray" href={`/catalog/feature_sets/${featureSet.id}/edit`} className="leading-4">
                    <div className="text-black font-medium">{featureSet.name}</div>
                    <div className="text-gray-600 text-sm">{featureSet.id}</div>
                  </Link>
                </Table.RowHeaderCell>
                <Table.Cell>{featureSet.description || '-'}</Table.Cell>
                <Table.Cell>{new Date(featureSet.created_at * 1000).toLocaleDateString()}</Table.Cell>
                <Table.Cell>{new Date(featureSet.updated_at * 1000).toLocaleDateString()}</Table.Cell>
                <Table.Cell>
                  <Link color="gray" href={`/catalog/feature_sets/${featureSet.id}/edit`}>
                    Edit
                  </Link>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table.Root>
      )}
    </div>
  );
}
