import {useParams} from "react-router";
import useSWR from "swr";
import apiClient from "~/lib/apiClient";
import WorkflowLayout from "~/pages/convert/workflows/WorkflowLayout";


export default function WorkflowResultsPage() {

  const params = useParams();

  const {data: workflow, error} = useSWR(
    `/convert/workflows/${params.id}`,
    url => apiClient.get(url).then(res => res.data)
  );

  if (!workflow) return;

  return (
    <WorkflowLayout workflow={workflow}>
      Worklolx
    </WorkflowLayout>
  )
}
