import { Avatar, Heading, Link, Table } from "@radix-ui/themes";
import useSWR from "swr";
import { getFetcher } from "~/lib/apiClient";
import PageHeader from "~/components/PageHeader";
import AnchorButton from "~/components/AnchorButton";
import { ProductFamily } from "~/types";


export default function ProductFamiliesIndexPage() {

  const { data: productFamilies, error } = useSWR('/catalog/product_families', getFetcher<{ data: ProductFamily[] }>);

  return (
    <div className="space-y-6 p-10">
      <PageHeader>
        <Heading>Product Families</Heading>
        <AnchorButton variant="outline" href={`/catalog/product_families/create`}>Create New</AnchorButton>
      </PageHeader>

      {productFamilies && (
        <Table.Root variant="surface" layout="fixed" >
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeaderCell>Icon</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Name</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Lookup Key</Table.ColumnHeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {productFamilies.data.map((family) => (
              <Table.Row key={family.id}>
                <Table.Cell>
                  <Avatar fallback={"IC"} size="2" src={family.icon?.href} />
                </Table.Cell>
                <Table.Cell><Link href={`/catalog/product_families/${family.id}`} weight="medium" color="gray" highContrast>{family.name}</Link></Table.Cell>
                <Table.Cell>{family.lookup_key}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table.Root>

      )}
    </div>
  );
}
