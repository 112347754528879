import {NavLink, Outlet} from "react-router-dom";
import PageHeader from "~/components/PageHeader";
import cx from "classnames";

import {TabNav} from "@radix-ui/themes";

function NavItem({ href, children, ...rest }) {

  return (
    <NavLink to={href} >
      {({ isActive, isPending }) => (
        <TabNav.Link asChild={true} active={isActive}>
          <span>{children}</span>
        </TabNav.Link>
      )}
    </NavLink>
  )
}

export default function SettingsLayout() {

  return (
    <div>
      {/*<PageHeader>*/}
      {/*  <h1 className="text-lg font-semibold">Settings</h1>*/}
      {/*</PageHeader>*/}

      {/*<div className="space-y-2">*/}
      {/*  <div className="mx-auto  overflow-x-auto overflow-y-visible border-b">*/}

      {/*    <TabNav.Root>*/}
      {/*      <NavItem href={`/settings/organization`}>Organization</NavItem>*/}
      {/*      <NavItem href={`/settings/members`}>Members</NavItem>*/}
      {/*      <NavItem href={`/settings/profile`}>Profile</NavItem>*/}
      {/*      <NavItem href={`/settings/theme`}>Theme</NavItem>*/}
      {/*      <NavItem href={`/settings/integrations`}>Integrations</NavItem>*/}
      {/*      <NavItem href={`/settings/developers`}>Developers</NavItem>*/}
      {/*    </TabNav.Root>*/}
      {/*  </div>*/}
      {/*</div>*/}

      <Outlet/>
    </div>
  )
}
