import { Heading, Link, Table } from "@radix-ui/themes";
import useSWR from "swr";
import { getFetcher } from "~/lib/apiClient";
import { formatDateTime } from "~/utils/dateTime";
import cx from "classnames";

export default function AttributionsListPage() {

  const { data: collection, error } = useSWR('/convert/attributions', getFetcher);

  return (
    <div className="p-10">

      <Heading size="5" className="mb-4">Attributions</Heading>


      {collection && (
        <Table.Root variant="surface">
          <Table.Header>
            <Table.ColumnHeaderCell>ID</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Paywall</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Customer</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Gross</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Net</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Data</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Purchase</Table.ColumnHeaderCell>
          </Table.Header>

          <Table.Body>
            {collection?.data.map(attribution => (
              <Table.Row key={attribution.id} className={cx({
                'bg-gray-100': attribution.environment === 'unknown',
                'bg-green-100': attribution.environment === 'live',
                'bg-yellow-100': attribution.environment === 'test',
              })}>
                <Table.Cell>
                  <div>{attribution.id} </div>
                  <div>{formatDateTime(attribution.created_at)}</div>
                </Table.Cell>
                <Table.Cell>
                  <Link href={`/convert/workflows/${attribution.workflow_id}`}>{attribution.paywall.name}</Link>
                </Table.Cell>
                <Table.Cell>{attribution.customer.reference_id}</Table.Cell>
                <Table.Cell>{attribution.proceeds_amount_gross.amount}</Table.Cell>
                <Table.Cell>{attribution.proceeds_amount_net.amount}</Table.Cell>
                <Table.Cell>{JSON.stringify(attribution.data)}</Table.Cell>
                <Table.Cell>
                  <div className="max-w-30 overflow-hidden">
                  {JSON.stringify(attribution.purchase)}
                  </div>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
          
        </Table.Root>
      )}
    </div>
  )
}

