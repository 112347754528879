import TextInput from "~/components/form/TextInput";

import * as Form from "@radix-ui/react-form";
import {useState} from "react";
import ColorInput from "~/components/form/ColorInput";
import {Button, Heading} from "@radix-ui/themes";
import SelectInput from "~/components/form/SelectInput";
import Debug from "~/components/Debug";
import Alert from "~/components/Alert";
import useSWR from "swr";
import apiClient, {getFetcher} from "~/lib/apiClient";
import {get} from "lodash";
import {toast} from "react-toastify";
import PageHeader from "~/components/PageHeader";

const DEFAULT_THEME = {
  border_radius: 'sm',
  page_bg_color: '',
  button_bg: '',
  button_text_color: '',
  link_color: '',
  base_font_size: '',
  base_text_color: '',
  paragraph_line_height: '',
  font_family: '',
  vertical_spacing: '',
  input_size: '2',
  input_variant: 'surface',
  input_color: '',
  accent_color: '',
}
function only(theme, strings: string[]) {
  const obj = {}
  strings.forEach(key => obj[key] = theme[key])
  return obj
}

function ThemeEditor({ theme, mutate }) {

  const [data, setData] = useState(only(theme, Object.keys(DEFAULT_THEME)));
  const [isSaving, setIsSaving] = useState(false);
  const [errors, setErrors] = useState(null);

  function field(id, label) {
    return {
      id,
      label: label || id,
      name: id,
      value: data[id],
      errors: get(errors, id),
      onChange: (newValue) => setData(prev => ({...prev, [id]: newValue})),
    }
  }

  function handleSubmit(evt) {
    evt.preventDefault();
    setErrors(null);
    setIsSaving(true);

    apiClient.put(`/convert/themes/${theme.id}`, data)
      .then(() => {
        mutate();
        toast.success('Theme updated');
      })
      .catch(err => {
        setErrors(err.response.data.errors || err.response.data.message);
      })
      .finally(() => setIsSaving(false))
  }

  return (
    <Form.Root className="p-10 space-y-2" onSubmit={handleSubmit}>
      <PageHeader>
        <Heading>Theme</Heading>
      </PageHeader>

      <SelectInput {...field('border_radius')} options={[
        {name: 'None', id: 'none'},
        {name: 'Small', id: 'sm'},
        {name: 'Medium', id: 'md'},
        {name: 'Large', id: 'lg'},
        {name: 'Extra Large', id: 'xl'},
        {name: 'Full', id: 'full'}
      ]}/>
      <ColorInput {...field('page_bg_color')} />
      <TextInput {...field('button_bg')} />
      <ColorInput {...field('button_text_color')} />
      <ColorInput {...field('link_color')} />
      <TextInput {...field('base_font_size')} type="number" />
      <ColorInput {...field('base_text_color')} />
      <TextInput {...field('paragraph_line_height')} type="number" />
      <TextInput {...field('font_family')} />
      <SelectInput {...field('vertical_spacing')} options={[
        {name: 'Small', id: 'sm'},
        {name: 'Medium', id: 'md'},
        {name: 'Large', id: 'lg'},
      ]} />
      <SelectInput {...field('input_size')} options={[
        {name: 'Small', id: '1'},
        {name: 'Medium', id: '2'},
        {name: 'Large', id: '3'},
      ]}/>
      <SelectInput {...field('input_variant')} options={[
        {name: 'Surface', id: 'surface'},
        {name: 'Classic', id: 'classic'},
        {name: 'Soft', id: 'soft'},
        {name: 'Stripe', id: 'stripe'},
      ]} />
      <ColorInput {...field('input_color')} />
      <ColorInput {...field('accent_color')} />

      <div className="py-4">
        <Button type="submit" loading={isSaving}>Update</Button>
      </div>


      {/*<Debug errors={errors}/>*/}
      {/*<Debug {...data}/>*/}
    </Form.Root>
  )
}


export default function ThemeEditPage() {

  const {data: themes, error, mutate} = useSWR('/convert/themes', getFetcher);

  const theme = get(themes, 'data.0');

  return (
    <div>
      {theme && (
        <ThemeEditor theme={theme} mutate={mutate}/>
      )}
    </div>
  )
}
