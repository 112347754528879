import cx from 'classnames'

export type ActiveInactivePillProps = {
  text: string;
  status: "active" | "inactive";
  className?: string;
}

export default function ActiveInactivePill({ text, status, className }: ActiveInactivePillProps) {
  return (
    <>
      <div className={cx("items-center px-2 rounded-lg text-sm inline-flex font-semibold", {
        "bg-green-100 text-green-600": status === 'active',
        "bg-gray-100 text-gray-600": status === 'inactive'
      }, className)}>{text}</div>
    </>
  )
}
