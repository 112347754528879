import { Callout } from "@radix-ui/themes";
import { InfoIcon } from "lucide-react";

export default function NotImplementedWarning() {
  return (
    <Callout.Root>
      <Callout.Icon>
        <InfoIcon />
      </Callout.Icon>
      <Callout.Text>
        This page is not implemented yet.
      </Callout.Text>
    </Callout.Root>
  )
}