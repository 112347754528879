import { Button, Table } from '@radix-ui/themes';
import { useState, useMemo } from 'react';
import useSWR from 'swr';
import { RouteWrapper } from '../../../components/RouteWrapper';
import { getFetcher } from '~/lib/apiClient';
import { Link, useSearchParams } from 'react-router-dom';
import AnchorButton from '~/components/AnchorButton';
import { AlertTriangle } from 'lucide-react';

interface Twin {
  id: string;
  object: 'twin';
  type: string;
  data: Record<string, any>;
  name: string;
  provider: string;
  environment: 'test' | 'live';
}

interface Charge {
  id: string;
  object: 'charge';
  product?: {
    object: 'product';
    id: string;
  };
  type: string;
  mode: string;
  name: string;
  amount: number;
  amount_formatted: string;
  currency: string;
  properties: Record<string, any>;
  twins?: Twin[];
}

interface ChargesResponse {
  data: Charge[];
  meta: PaginationMeta;
}

interface PaginationMeta {
  current_page: number;
  from: number;
  to: number;
  total: number;
  last_page: number;
}

interface Bsp {
  id: string;
  type: string;
  environment: 'live' | 'test';
  name: string;
  current_state: string;
  provider_id: string;
}

interface BspResponse {
  data: Bsp[];
}

export default function ChargesListPage() {
  const [searchParams] = useSearchParams();
  const page = Number(searchParams.get("page")) || 1;

  const { data: collection, error, isLoading } = useSWR<ChargesResponse>(
    `/billing/charges?page=${page}`,
    getFetcher
  );
  const { data: bspData } = useSWR<{ data: Bsp[] }>(
    '/billing/providers',
    getFetcher
  );

  const getBillingProviderStatus = (twins?: Twin[]) => {
    if (!twins?.length) return 'No providers';
    
    const hasTestProvider = twins.some(twin => twin.environment === 'test');
    const hasLiveProvider = twins.some(twin => twin.environment === 'live');
    
    if (hasTestProvider && hasLiveProvider) return 'Test & Live';
    if (hasTestProvider) return 'Test only';
    if (hasLiveProvider) return 'Live only';
    return 'Unknown';
  };

  return (
    <div className="p-6">
      {bspData?.data && (
        <div className="mb-6">
          <h3 className="text-lg font-semibold mb-2">Connected Billing Providers</h3>
          <div className="flex gap-2">
            {bspData.data.map((bsp) => (
              <span
                key={bsp.id}
                className={`px-3 py-1 rounded-full text-sm ${
                  bsp.environment === 'live'
                    ? 'bg-green-100 text-green-800'
                    : 'bg-yellow-100 text-yellow-800'
                }`}
              >
                {bsp.name} ({bsp.environment})
              </span>
            ))}
          </div>
        </div>
      )}

      <Table.Root>
        <Table.Header>
          <Table.Row>
            <Table.ColumnHeaderCell>Name</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Type</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Amount</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Mode</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Properties</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Billing Provider Status</Table.ColumnHeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {collection?.data.map((charge) => (
            <Table.Row key={charge.id}>
              <Table.Cell>
                <Link 
                  to={`/billing/charges/${charge.id}`}
                  className="text-blue-600 hover:text-blue-800 font-bold"
                >
                  {charge.name}
                </Link>

                <div className="pt-2">
                  <h4 className="text-sm font-semibold">Included in plans:</h4>
                  <ul className="text-sm">
                    {charge.inclusions.map(inclusion => (
                      <li key={inclusion.id}>
                        <Link to={`/catalog/pricing/plans/${inclusion.plan}`} className="text-link">
                          {inclusion.plan}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </Table.Cell>
              <Table.Cell>{charge.type}</Table.Cell>
              <Table.Cell>{charge.amount_formatted}</Table.Cell>
              <Table.Cell>{charge.mode}</Table.Cell>
              <Table.Cell>
                <pre className="text-sm">
                  {JSON.stringify(charge.properties, null, 2)}
                </pre>
              </Table.Cell>
              <Table.Cell>
                <span className={`px-2 py-1 rounded-full text-sm inline-flex items-center gap-1 ${
                  getBillingProviderStatus(charge.twins) === 'Test & Live' 
                    ? 'bg-green-100 text-green-800'
                    : getBillingProviderStatus(charge.twins) === 'Test only'
                    ? 'bg-yellow-100 text-yellow-800'
                    : getBillingProviderStatus(charge.twins) === 'Live only'
                    ? 'bg-yellow-200 text-yellow-900'
                    : 'bg-gray-100 text-gray-800'
                }`}>
                  {getBillingProviderStatus(charge.twins) !== 'Test & Live' && (
                    <AlertTriangle size={14} />
                  )}
                  {getBillingProviderStatus(charge.twins)}
                </span>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table.Root>

      {collection && (
        <Pagination collection={collection} />
      )}
    </div>
  );
}

function Pagination({ collection }) {
  const prevPage = useMemo(() => {
    if (collection.meta.current_page === 1) {
      return 1;
    }
    return collection.meta.current_page - 1;
  }, [collection]);

  const nextPage = useMemo(() => {
    if (collection.meta.current_page === collection.meta.last_page) {
      return collection.meta.last_page;
    }
    return collection.meta.current_page + 1;
  }, [collection]);

  return (
    <div className="flex justify-between px-4 py-2">
      <div className="flex items-center">
        <b>Showing {collection.meta.from} to {collection.meta.to}</b>&nbsp;of {collection.meta.total} results
      </div>

      <div className="flex gap-2">
        <AnchorButton
          href={`/billing/charges?page=${prevPage}`}
          disabled={collection.meta.current_page === 1}>
          Prev
        </AnchorButton>
        <AnchorButton
          href={`/billing/charges?page=${nextPage}`}
          disabled={collection.meta.current_page === collection.meta.last_page}>
          Next
        </AnchorButton>
      </div>
    </div>
  );
}