import { BrowserRouter } from 'react-router-dom'
import { RouteWrapper } from '~/components/RouteWrapper'
import { ErrorBoundary } from '~/components/ErrorBoundary'
import {useAuth} from "~/hooks/useAuth";
import {PlandalfProvider} from "@plandalf/react-plandalf-js";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Theme, ThemePanel} from '@radix-ui/themes';
import FeatureSetEditPage from "~/pages/catalog/feature_sets/FeatureSetEditPage";


function App() {

  return (
    <BrowserRouter basename="/">
      <Application/>
    </BrowserRouter>
  )
}

function Application() {
  const {user} = useAuth({
    middleware: "none"
  });


  return (
    <>
    <ErrorBoundary>
      <Theme accentColor="teal" grayColor="gray" >
        <RouteWrapper />
        {/*<ThemePanel />*/}
      </Theme>
    </ErrorBoundary>
    <ToastContainer
      position="top-center"
      hideProgressBar={true}
      closeOnClick={true}
      autoClose={1500}
      limit={1}
    />
    </>
  )
}

export default App;
