import useSWR from "swr";
import { getFetcher } from "~/lib/apiClient";
import React from "react";
import { Button, Table, Heading } from "@radix-ui/themes";
import { Link } from "react-router-dom";
import PageHeader from "~/components/PageHeader";
import ActiveInactivePill from "~/components/ActiveInactivePill";

export default function MetricsListPage() {
  const { data: metrics, error } = useSWR('/usage/metrics', getFetcher);

  if (error) return <div>Failed to load metrics</div>;
  if (!metrics) return <div>Loading...</div>;

  return (
    <div className="p-10">
      <PageHeader>
        <div className="flex justify-between items-center">
          <Heading>Metrics</Heading>
          <Link to="/catalog/metrics/create">
            <Button>Create Metric</Button>
          </Link>
        </div>
      </PageHeader>

      <Table.Root variant="surface">
        <Table.Header>
          <Table.Row>
            <Table.ColumnHeaderCell>Name</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Event Name</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Aggregation</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Type</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Status</Table.ColumnHeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {metrics.data.map((metric) => (
            <Table.Row key={metric.id}>
              <Table.Cell>
                <Link to={`/catalog/metrics/${metric.id}/edit`}>
                  {metric.name}
                </Link>
              </Table.Cell>
              <Table.Cell>{metric.event_name}</Table.Cell>
              <Table.Cell>{metric.aggregation}</Table.Cell>
              <Table.Cell>{metric.type}</Table.Cell>
              <Table.Cell>
                <ActiveInactivePill active={metric.status === 'active'} />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table.Root>
    </div>
  );
}
