import { useParams } from "react-router";
import useSWR from "swr";
import apiClient, { getFetcher } from "~/lib/apiClient";
import Alert from "~/components/Alert";
import * as Form from "@radix-ui/react-form";
import { Button } from "@radix-ui/themes";
import SelectInput from "~/components/form/SelectInput";
import TextInput from "~/components/form/TextInput";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { get } from "lodash";

const planTypes = ["custom", "standard", "offer", "provisional", "addon"];


function PlanListItem({ plan, onSave, mutate }) {
  const [isEditing, setIsEditing] = useState(false);
  const [editedPlan, setEditedPlan] = useState({
    display_name: plan.display_name,
    type: plan.type,
  });

  const handleEdit = () => setIsEditing(true);

  const handleChange = (field, value) => {
    setEditedPlan(prev => ({ ...prev, [field]: value }));
  };

  const handleSave = async (event) => {
    event.preventDefault();
    try {
      await onSave({ ...plan, ...editedPlan });
      mutate();
      setIsEditing(false);
    } catch (error) {
      console.error("Error saving plan:", error);
    }
  };

  return (
    <li className="bg-white rounded-lg border p-4">
      <div className="flex gap-1 items-center">
        Plan <Link to={`/catalog/pricing/plans/${plan.id}`} className="text-link">{plan.name}</Link>
      </div>
      <div className="text-gray-600 font-medium text-sm font-mono">{plan.id}</div>
      {isEditing ? (
        <Form.Root onSubmit={handleSave} className="space-y-4">
          <div className="space-y-1">
            <span>Display name</span>
            <TextInput
              value={editedPlan.display_name}
              onChange={(value) => handleChange('display_name', value)}
            />
          </div>

          <div className="space-y-1">
            <span>Type</span>
            <SelectInput
              options={planTypes.map(type => ({ id: type, name: type }))}
              value={editedPlan.type}
              onValueChange={(value) => handleChange('type', value)}
            />
          </div>
          <Button type="submit" variant="primary">
            Save
          </Button>
        </Form.Root>
      ) : (
        <div className="mt-2 space-y-2">
          <p>Display Name: {plan.display_name}</p>
          <p>Type: {plan.type}</p>
          <Button onClick={handleEdit} variant="secondary">
            Edit
          </Button>
        </div>
      )}
    </li>
  );
}

export default function PackageDetailPage() {
  const { packageId } = useParams();
  const { data: schemes } = useSWR('/pricing/schemes', getFetcher);
  const {
    data: pkg,
    error,
    mutate
  } = useSWR(`/pricing/packages/${packageId}`, getFetcher);

  const [isEditingPackage, setIsEditingPackage] = useState(false);
  const [editedPackage, setEditedPackage] = useState({
    package_name: '',
    pricing_scheme_id: ''
  });

  const [formErrors, setFormErrors] = useState<Record<string, string[]>>({});
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (pkg) {
      setEditedPackage({
        package_name: pkg.name,
        pricing_scheme_id: pkg.scheme?.id
      });
    }
  }, [pkg]);

  const handlePackageChange = (field, value) => {
    setEditedPackage(prev => ({ ...prev, [field]: value }));
  };

  const handlePackageSave = (event) => {
    event.preventDefault();
    setFormErrors({});
    setIsSaving(true);

    apiClient
      .patch(`/pricing/packages/${packageId}`, editedPackage)
      .then((response) => {
        if (response.data) {
          return mutate().then(() => {
            setIsEditingPackage(false);
          });
        }
      })
      .catch((error) => {
        console.error("Error updating package:", error);
        
        if (error.response?.status === 422) {
          setFormErrors(error.response.data.errors || {});
        } else {
          setFormErrors({
            general: [error.response?.data?.message || 'An unexpected error occurred']
          });
        }
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  if (!pkg) return null;

  const handleSave = async (plan) => {
    try {
      await apiClient.patch(`/pricing/plans/${plan.id}`, {
        display_name: plan.display_name,
        type: plan.type,
      });
      mutate();
    } catch (error) {
      console.error("Error updating plan:", error);
    }
  };

  return (
    <div className="p-4">
      {error && (
        <Alert type="error">
          <pre>{JSON.stringify(error, null, 2)}</pre>
        </Alert>
      )}
      
      <div className="mb-8 bg-white rounded-lg border p-4">
        {isEditingPackage ? (
          <Form.Root onSubmit={handlePackageSave} className="space-y-4">
            {formErrors.general && (
              <Alert type="error">
                {formErrors.general.map((error, index) => (
                  <div key={index}>{error}</div>
                ))}
              </Alert>
            )}

            <div className="space-y-1">
              <span>Package Name</span>
              <TextInput
                value={editedPackage.package_name}
                onChange={(value) => handlePackageChange('package_name', value)}
                errors={get(formErrors, 'package_name') as [string]}
              />
            </div>

            <div className="space-y-1">
              <span>Pricing Scheme</span>
              <SelectInput
                options={schemes?.data?.map(scheme => ({
                  id: scheme.id,
                  name: scheme.name
                })) || []}
                value={editedPackage.pricing_scheme_id}
                onValueChange={(value) => handlePackageChange('pricing_scheme_id', value)}
                errors={get(formErrors, 'pricing_scheme_id') as [string]}
              />
            </div>
            
            <div className="flex gap-2">
              <Button 
                type="submit" 
                disabled={isSaving}
              >
                {isSaving ? 'Saving...' : 'Save'}
              </Button>
              <Button 
                type="button" 
                variant="soft" 
                onClick={() => {
                  setIsEditingPackage(false);
                  setFormErrors({});
                }}
                disabled={isSaving}
              >
                Cancel
              </Button>
            </div>
          </Form.Root>
        ) : (
          <div className="flex justify-between items-center">
            <h2 className="text-2xl font-bold">Package: {pkg.name}</h2>
            <Button onClick={() => setIsEditingPackage(true)} variant="secondary">
              Edit Package
            </Button>
          </div>
        )}
      </div>

      <ul className="space-y-4">
        {pkg.plans.map((plan) => (
          <PlanListItem
            key={plan.id}
            plan={plan}
            onSave={handleSave}
            mutate={() => mutate()}
          />
        ))}
      </ul>
    </div>
  );
}
