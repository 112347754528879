import { Button, Heading, TextField } from "@radix-ui/themes";
import apiClient, { getFetcher } from "~/lib/apiClient";

import * as Form from "@radix-ui/react-form";
import { useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import PageHeader from "~/components/PageHeader";
import useSWR from "swr";
import { ProductFamily } from "~/types";
import ProductList from "./components/ProductList";

type AssetUploadFormProps = {
  onFileChange: (file: File) => void;
}

function AssetUploadForm({ onFileChange }: AssetUploadFormProps) {
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const validateImage = (file) => {
    const validTypes = ['image/jpeg', 'image/png', 'image/gif'];
    const maxSize = 5 * 1024 * 1024; // 5MB

    if (!validTypes.includes(file.type)) {
      return 'Invalid file type. Only JPG, PNG, and GIF are allowed.';
    }

    if (file.size > maxSize) {
      return 'File size exceeds the limit of 5MB.';
    }

    return '';
  };

  const handleFileChange = (event) => {
    setSuccessMessage('');
    setError('');

    const selectedFile = event.target.files[0];
    const validationError = validateImage(selectedFile);

    if (validationError) {
      setError(validationError);
    } else {
      onFileChange(selectedFile);
    }
  };


  return (
    <div>
      <h2>Upload icon</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
      <input type="file" accept="image/*" onChange={handleFileChange} />
    </div>
  )
}

type ProductFamilyPageProps = {
  onSubmit?: (payload) => void;
  submitText?: string;
  title?: string;
  family?: ProductFamily;
  selectedProducts?: string[];
}

type CreateUpdateProductFamilyState = Pick<ProductFamily, 'name' | 'lookup_key'> & {
  selected_products?: string[];
  asset_id?: string
}

function ProductFamilyPage({ onSubmit, family, selectedProducts: initialSelectedProducts = [], submitText = 'Create', title = 'Create new' }: ProductFamilyPageProps) {
  const [data, setData] = useState<CreateUpdateProductFamilyState>({
    name: family?.name,
    lookup_key: family?.lookup_key,
    asset_id: null,
    selected_products: initialSelectedProducts
  });

  const [icon, setIcon] = useState<File>();

  const uploadFile = async () => {
    if (!icon) return null;

    // Get the signed URL from the backend
    const response = await apiClient.post('/media/assets', {
      original_name: icon.name,
      content_type: icon.type,
      file_size: icon.size,
    });

    const {
      upload_url: uploadUrl,
    } = response.data;

    // Upload the file to the signed URL
    const options = {
      headers: {
        'Content-Type': icon.type,
      }
    };

    await apiClient.put(uploadUrl, icon, options);

    return response.data.asset;
  };

  async function handleSubmit(evt) {
    evt.preventDefault();
    console.log('submitting form');

    const asset = await uploadFile();
    onSubmit({ ...data, asset_id: asset?.id ?? null });
  }

  return (
    <div className="space-y-6 p-10">
      <PageHeader>
        <Heading>{title}</Heading>
      </PageHeader>
      <Form.Root onSubmit={handleSubmit} className="space-y-4">
        <div className="space-y-1">
          <span>Name</span>
          <TextField.Root value={data.name} onChange={(value) => setData({ ...data, name: value.target.value })} placeholder="Name" />
        </div>
        <div>
          <span>Lookup Key</span>
          <TextField.Root value={data.lookup_key} onChange={(value) => setData({ ...data, lookup_key: value.target.value })} placeholder="Lookup Key" />
        </div>

        <AssetUploadForm onFileChange={(file) => setIcon(file)} />

        <ProductList
          family={family}
          selectedProducts={data.selected_products}
          onSelectChange={(selectedProducts) => setData({ ...data, selected_products: selectedProducts })}
        />
        <Button>{submitText}</Button>
      </Form.Root>
    </div>
  )
}

function UpdateFamily() {
  const { familyId } = useParams();
  const navigate = useNavigate();
  const { data: productFamily, error } = useSWR(`/catalog/product_families/${familyId}`, getFetcher<ProductFamily>);

  const selectedProducts = useMemo(() => {
    if (!productFamily?.products) return [];

    return productFamily.products.map(product => product.id);
  }, [productFamily]);

  async function handleSubmit(payload) {
    apiClient.put(`/catalog/product_families/${familyId}`, payload)
      .then((response) => {
        console.log(response);
        toast.success('Product family updated');
        navigate('/catalog/product_families');
      })
      .catch((error) => {
        console.error(error)
        toast.error('Failed to update product family');
      });
  }

  if (!productFamily) return <div>Loading...</div>;

  return (
    <ProductFamilyPage onSubmit={handleSubmit} family={productFamily} selectedProducts={selectedProducts} title="Update" submitText="Update" />
  )
}

function CreateFamily() {
  const navigate = useNavigate();

  async function handleSubmit(payload) {
    apiClient.post('/catalog/product_families', payload)
      .then((response) => {
        console.log(response);
        toast.success('Product family created');
        navigate('/catalog/product_families');
      })
      .catch((error) => {
        console.error(error)
        toast.error('Failed to create product family');
      });
  }

  return (
    <ProductFamilyPage onSubmit={handleSubmit} />
  )
}

export type CreateEditProductFamilyPageProps = {
  update?: boolean
}

export default function CreateEditProductFamilyPage({ update }: CreateEditProductFamilyPageProps) {
  if (update) {
    return <UpdateFamily />
  }

  return <CreateFamily />
}

