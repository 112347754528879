import Axios from 'axios'

const apiClient = Axios.create({
  baseURL: process.env.API_URL || 'http://localhost:8000/v1',
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
  withCredentials: true,
  withXSRFToken: true
});

export const getFetcher = <T>(url: string) => apiClient.get<T>(`${url}`).then(res => res.data);

export default apiClient
