import {before, capitalize} from "lodash";
import {TextField} from "@radix-ui/themes";
import React, {ChangeEvent, forwardRef, useCallback, useEffect, useRef, useState} from "react";
import slugify from "~/util/slugify";
import * as Form from '@radix-ui/react-form';

export type TextInputProps = {
  helpText?: string;
  className?: string;
  validationError?: string;
  label?: string;
  errors?: [string] | null;
  beforeChangeFormatter?: ValueFormatterType[] | ValueFormatterType;
  onChange?: (value: string, e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | null) => void
  value: string;
} & Omit<TextField.RootProps, 'onChange'>;

export enum ValueFormatter {
  // int = 'int',
  // decimal = 'decimal', // Truncate numbers to 2 decimals
  // triDecimal = 'triDecimal', // Truncate numbers to 3 decimals
  // quadDecimal = 'quadDecimal', // Truncate numbers to 4 decimals
  // positiveNumber = 'positiveNumber',
  slug = 'slug', // Replace all the spaces by "_"
  // chargeDecimal = 'chargeDecimal', // Truncate charge numbers to 15 decimals
  lowercase = 'lowercase',
  trim = 'trim',
  // dashSeparator = 'dashSeparator',
}
export type ValueFormatterType = keyof typeof ValueFormatter


export const formatValue = (value: string, formatterFunctions?: ValueFormatterType[] | ValueFormatterType) => {
  let formattedValue = value

  if (value === undefined || value === null || value === '') return ''

  if (!formatterFunctions || !formatterFunctions.length) return value

  if (formatterFunctions.includes(ValueFormatter.slug)) {
    formattedValue = String(formattedValue)
      .replace(/\s+/g, '_')  // Replace one or more spaces with a single underscore
      .replace(/[^A-Za-z0-9_-]/g, '');  // Remove all characters except alphanumeric, underscore, and dash
  }

  if (formatterFunctions.includes(ValueFormatter.trim)) {
    formattedValue = String(formattedValue).trim()
  }

  if (formatterFunctions.includes(ValueFormatter.lowercase)) {
    formattedValue = String(formattedValue).toLowerCase()
  }

  return !formattedValue ? '' : formattedValue
}

const TextInput = forwardRef<HTMLDivElement, TextInputProps>((props, ref) => {
  const {
    value,
    label = capitalize(props.name),
    placeholder,
    helpText,
    validationError,
    onChange,
    errors,
    type = 'text',
    autoComplete = 'on',
    beforeChangeFormatter,
    ...otherProps,
  } = props;

  const [localValue, setLocalValue] = useState<string | number>('')

  const updateValue = (newValue: string, evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue, selectionStart } = evt.currentTarget;

    const formattedValue = formatValue(newValue, beforeChangeFormatter)

    if (formattedValue === null || formattedValue === undefined) return

    const cursorOffset = formattedValue.length - inputValue.length;

    setLocalValue(formattedValue)

    onChange && onChange(formattedValue as string, evt);

    window.requestAnimationFrame(() => {
      if (type !== 'number') {
        evt.currentTarget?.selectionStart = evt.currentTarget?.selectionEnd = selectionStart + cursorOffset;
      }
    });
  }

  useEffect(() => {
    if (value !== null || value !== undefined) {
      setLocalValue(value)
    } else {
      setLocalValue('')
    }
  }, [value])

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      updateValue(event.currentTarget.value, event)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onChange, beforeChangeFormatter],
  )

  return (
    <Form.Field name={props.name} >
      <Form.Label className="FormLabel">
        {label}
        {validationError && <span className="ml-2 text-red-700 text-xs">*{validationError}</span>}
      </Form.Label>

      <TextField.Root
        ref={ref}
        variant="surface"
        id="name"
        type={type}
        {...otherProps}
        onChange={handleChange}
        value={localValue}
        autoComplete={autoComplete}
        placeholder={placeholder || label}
      />

      {errors && (<div className="text-sm mt-2 text-rose-600">{errors[0]}</div>)}
      {helpText && <p className="text-gray-800 text-xs pt-1">{helpText}</p>}
    </Form.Field>
  )
})

export default TextInput;
