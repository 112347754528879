import {useParams} from "react-router";
import useSWR from "swr";
import apiClient, {getFetcher} from "~/lib/apiClient";
import {useEffect, useState} from "react";
import WorkflowLayout from "~/pages/convert/workflows/WorkflowLayout";
import {Link} from "@radix-ui/themes";
import { useState } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "lucide-react";
const PLANDALF_PAYWALL_URL = process.env.PLANDALF_PAYWALL_URL;

export default function WorkflowSandboxPage() {

  const { paywallId } = useParams();
  const {data: paywall, error: paywallError} = useSWR(`/convert/paywalls/${paywallId}`, getFetcher);
  const [sandboxUser, setSandboxUser] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (sandboxUser) return;
    if (!paywall) return;
    apiClient.post(`/convert/workflows/${paywall?.workflow.id}/agents`)
      .then(r => {
        setSandboxUser(r.data);
      })
  }, [sandboxUser, paywall]);

  if (!sandboxUser) return <div>loading..</div>

  const searchParams = {
    paywall: paywall?.id,
    collector: sandboxUser.collector,
    // client: paywall?.workflow.client,
    agent: sandboxUser.token,
    placement: 'sandbox',
  }

  // todo: create the session here, then pass it to the iframe
  const search = new URLSearchParams(searchParams).toString();
  const url = `${PLANDALF_PAYWALL_URL}?${search}`;

  if (!paywall.workflow) return null;

  return (
    <WorkflowLayout workflow={paywall.workflow}>
       <div className="p-2 w-xl flex">
        <Link href={url} className="truncate">
          {url}
        </Link>
       </div>
      <div className="h-full flex-grow bg-gray-900 flex items-center justify-center p-6 bg-black/60 backdrop-blur">
        <div className="max-w-[1000px] max-h-[640px] h-full w-full border rounded-xl overflow-hidden bg-white">
          <iframe src={url} className="w-full h-full border" frameBorder="0"/>
        </div>
      </div>
    </WorkflowLayout>
  )
}