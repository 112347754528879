import useSWR from "swr";
import apiClient, { getFetcher } from "~/lib/apiClient";
import React, { useState } from "react";
import { get } from "lodash";
import setIn from 'lodash/fp/set';
import SelectInput from "~/components/form/SelectInput";
import TextInput from "~/components/form/TextInput";
import { Form } from "@radix-ui/react-form";
import {
  Button,
  Flex,
  RadioGroup,
  Callout,
  Heading,
} from "@radix-ui/themes";
import { useNavigate, Link } from "react-router-dom";
import PageHeader from "~/components/PageHeader";
import Alert from "~/components/Alert";

export default function CreateMetricPage() {
  const navigate = useNavigate();
  const [errors, setErrors] = useState(null);
  const [data, setData] = useState({
    lookup_key: '',
    aggregation: 'LATEST',
    type: 'persistent',
    field_name: '',
    event_name: '',
    feature_id: null
  });

  const { data: features } = useSWR('/catalog/features', getFetcher);

  function handleSubmit(evt) {
    evt.preventDefault();
    apiClient.post(`/usage/metrics`, data).then(res => {
      console.log("Metric created", res);
      navigate('/catalog/metrics');
    }).catch(err => {
      console.error("Failed to create metric", err);
      setErrors(err.response.data.errors || {
        message: err.response.data.message,
      });
    });
  }

  function field(id: string, label) {
    return {
      id,
      label,
      value: get(data, id),
      errors: get(errors, id),
      onChange: (value) => {
        setData(setIn(id, value, data));
      },
    }
  }

  function handleFsChange(value) {
    setData(setIn('feature_set_id', value, data));
  }

  return (
    <div className="p-10">
      <PageHeader>
        <Heading>Create Metric</Heading>
      </PageHeader>

      <div className="space-y-6">
        <Form onSubmit={handleSubmit} className="space-y-2">
          {errors?.message && (
            <Callout.Root size="2" color="red">
              <Callout.Text>{errors.message}</Callout.Text>
            </Callout.Root>
          )}

          <div className="mb-2">
            <label className="block text-sm font-semibold mb-2">
              Feature
            </label>
            <RadioGroup.Root 
              name="feature" 
              variant="surface" 
              value={data.feature_id}
              onValueChange={(value) => setData(setIn('feature_id', value, data))}
            >
              {features?.data.map((feature) => (
                <RadioGroup.Item key={feature.id} value={feature.id}>
                  {feature.name}
                </RadioGroup.Item>
              ))}
            </RadioGroup.Root>
            {features?.data.length === 0 && (
              <Alert>No features available. <Link to="/catalog/features/create">Create one</Link></Alert>
            )}
            {errors?.feature_id && (
              <div className="text-sm mt-2 text-rose-600">{errors.feature_id[0]}</div>
            )}
          </div>

          <TextInput
            placeholder="Event Name"
            {...field('event_name', 'Event Name')}
          />

          <SelectInput
            {...field('aggregation', 'Aggregation')}
            options={[
              { id: 'LATEST', name: 'Latest' },
              { id: 'SUM', name: 'Sum' },
              { id: 'COUNT', name: 'Count' },
              { id: 'MAX', name: 'Max' },
              { id: 'MIN', name: 'Min' },
            ]}
          />

          <SelectInput
            {...field('type', 'Type')}
            options={[
              { id: 'persistent', name: 'Persistent' },
              { id: 'transient', name: 'Transient' },
            ]}
          />

          <TextInput
            {...field('field_name', 'Field Name')}
            placeholder="Field Name"
          />

          <Flex justify="between">
            <Flex justify="end" gap="3" align="center">
              <Button variant="solid" type="submit" radius="large">Create Metric</Button>
            </Flex>
          </Flex>
        </Form>
      </div>
    </div>
  );
}
