import JSONEditor from "~/components/JSONEditor";
import * as Form from "@radix-ui/react-form"

export type CodeInputProps = {
  value: string;
  label?: string;
  id?: string;
  onChange?: (value: string) => void;
  errors?: string[];
  rows?: number;
  helpText?: string;
}

export default function CodeInput({ value, label, id, onChange, errors, rows, helpText }: CodeInputProps ) {
  return (
    <Form.Field name={id} > 
      <Form.Label className="FormLabel">
        {label}
      </Form.Label>

      <JSONEditor
        jsonInput={value}
        setJsonInput={onChange}
        errors={errors}
        rows={rows}
      />

      {errors && (<div className="text-sm mt-2 text-rose-600">{errors[0]}</div>)}
      {helpText && <p className="text-gray-400 text-xs pt-1">{helpText}</p>}
    </Form.Field>
  )

}
